import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { EnvironmentProvider } from "./Core/Hook/useEnvironment";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthenticationGuard } from "./Core/Authentication/AuthenticationGuard";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { initI18n } from "../i18next";
import { i18n } from "i18next";
import { TranslationGuard } from "./Core/TranslationGuard";
import { I18nextProvider } from "react-i18next";
import { LocalizationProvider } from "../ui-component-library/Localization/LocalizationProvider";
import { ThemeProvider } from "./Core/Layout/ThemeProvider";
import { Chart } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { CssBaseline } from "@mui/material";
import { NoDataOverlay } from "./Core/Plugins/NoDataOverlayPlugin";

const queryClient = new QueryClient();

export const getBaseUrl = () => {
  if (process.env.REACT_APP_BASE_URL) {
    return process.env.REACT_APP_BASE_URL;
  }

  if (process.env.NODE_ENV === "production") {
    return "/api/";
  }

  return "/";
};

const baseUrl = getBaseUrl();

if (process.env.NODE_ENV !== "production") {
  if (!document.title.startsWith("🔧")) {
    document.title = "🔧" + document.title;
  }
}

const TranslationLoadingIndicator = () => <div>Loading translation…</div>;
Chart.register(MatrixController, MatrixElement);
Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);
Chart.register(NoDataOverlay);

Chart.defaults.set("plugins.datalabels", { display: false });

function App() {
  const [i18nInstance, setI18nInstance] = useState<i18n | undefined>();

  useEffect(() => {
    if (!i18nInstance) {
      initI18n().then(function ({ t, i18n }) {
        setI18nInstance(i18n);
      });
    }
  }, [i18nInstance]);

  if (!i18nInstance) {
    return (
      <>
        <CssBaseline />
        <TranslationLoadingIndicator />
      </>
    );
  }

  return (
    <EnvironmentProvider environment={{ baseUrl }}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <I18nextProvider i18n={i18nInstance}>
            <LocalizationProvider>
              <TranslationGuard
                loadingIndicator={<TranslationLoadingIndicator />}
              >
                <AuthenticationGuard loginForm={<UnauthenticatedApp />}>
                  <AuthenticatedApp />
                </AuthenticationGuard>
              </TranslationGuard>
            </LocalizationProvider>
          </I18nextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </EnvironmentProvider>
  );
}

export default App;

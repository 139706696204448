import React from "react";
import { TextField } from "../../../ui-component-library/Form/Field/TextField";
import { Field, useField } from "formik";
import { useTranslation } from "react-i18next";
import { Switch } from "../../../ui-component-library/Form/Field/Switch";
import { EditorPanelContainer } from "./EditorPanelContainer";

interface Props {}

export const BrandInformationPanel: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation("fillLevel");
  const [field, , helpers] = useField("variant");
  return (
    <EditorPanelContainer headline={t("brand_options")}>
      <Switch
        label={t("brand.display")}
        checked={field.value === "brand"}
        onChange={(e, checked) => {
          helpers.setValue(checked ? "brand" : "simple");
        }}
      />
      <Field as={TextField} name="brand.name" label={t("brand.name")} />
      <Field as={TextField} name="brand.slogan" label={t("brand.slogan")} />
      <Field
        as={TextField}
        name="brand.color"
        label={t("brand.background_color")}
      />
    </EditorPanelContainer>
  );
};

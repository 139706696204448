import React from "react";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { LayoutState } from "../FillLevel/Dashboard/FillLevelScreen";

interface Props {
  color: string;
  icon: string;
}

type IconProps = {
  fontSize: "inherit";
  htmlColor: string;
};

const availableIcons = {
  ok: ["icon_ok_1", "icon_ok_2"],
  limit_exceeded: ["icon_limit_exceeded_1", "icon_limit_exceeded_2"],
  offline: ["icon_offline_1", "icon_offline_2"],
};

export const getAvailableIcons = (state: LayoutState): string[] => {
  return availableIcons[state] ?? [];
};

export const IndicatorIcon: React.FunctionComponent<Props> = ({
  color,
  icon,
}) => {
  const iconProps: IconProps = {
    fontSize: "inherit",
    htmlColor: color,
  };

  switch (icon) {
    case "icon_ok_1":
      return <DirectionsWalkIcon {...iconProps} />;
    case "icon_ok_2":
      return <ThumbUpIcon {...iconProps} />;
    case "icon_limit_exceeded_1":
      return <AccessibilityIcon {...iconProps} />;
    case "icon_limit_exceeded_2":
      return <ThumbDownIcon {...iconProps} />;
    case "icon_offline_1":
      return <CloudOffIcon {...iconProps} />;
    case "icon_offline_2":
      return <FlashOffIcon {...iconProps} />;
    default:
      return <DirectionsWalkIcon fontSize={"inherit"} htmlColor={color} />;
  }
};

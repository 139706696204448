import React from "react";
import { Stack } from "@mui/material";
import { FillLevelMetric, MetricItem, MetricType } from "./MetricItem";
import { FillLevelData } from "./FillLevelScreen";

interface Props {
  data: FillLevelData;
  metricTypes: MetricType[];
  color: string;
}

const buildMetrics = (
  metricTypes: MetricType[],
  data: FillLevelData
): FillLevelMetric[] => {
  return metricTypes.map((type) => {
    switch (type) {
      case "current":
        return { type: "current", value: data.current, unit: "" };
      case "available":
        return {
          type: "available",
          value: Math.max(0, data.limit - data.current),
          unit: "",
        };
      case "limit":
        return { type: "limit", value: data.limit, unit: "" };
      case "occupancy":
        let occupancy = 0;

        if (data.limit === 0) {
          occupancy = 0;
        } else {
          occupancy = Math.floor(Math.min(1, data.current / data.limit) * 100);
        }

        return { type: "occupancy", value: occupancy, unit: "%" };
      default:
        return { type, value: 0, unit: "" };
    }
  });
};

export const MetricsList: React.FunctionComponent<Props> = ({
  metricTypes,
  data,
  color,
}) => {
  const metrics = buildMetrics(metricTypes, data);

  return (
    <Stack
      direction={"row"}
      spacing={8}
      sx={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      {metrics.map((metric, index) => (
        <MetricItem key={index} metric={metric} color={color} />
      ))}
    </Stack>
  );
};

import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { StoreInfoWindow } from "./StoreInfoWindow";
import { Store } from "../../../generated/graphql";

interface LocationsMapProps {
  stores: Store[];
}

export const LocationsMap: React.FunctionComponent<LocationsMapProps> = ({
  stores,
}) => {
  const [activeMarker, setActiveMarker] = useState<number | null>(null);

  const handleOnLoad = (map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    stores.forEach((store) => {
      if (store.geoLocation) {
        bounds.extend({
          lat: store.geoLocation.latitude,
          lng: store.geoLocation.longitude,
        });
      }
    });
    map.fitBounds(bounds);
  };

  return (
    <LoadScript googleMapsApiKey={""}>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          zoomControl: true,
        }}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
      >
        {stores.map(
          (store) =>
            store.geoLocation && (
              <Marker
                key={store.id}
                position={{
                  lat: store.geoLocation.latitude,
                  lng: store.geoLocation.longitude,
                }}
                title={store.title}
                onClick={() => setActiveMarker(store.id)}
              >
                {activeMarker === store.id && (
                  <StoreInfoWindow
                    store={store}
                    onCloseClick={() => setActiveMarker(null)}
                  />
                )}
              </Marker>
            )
        )}
      </GoogleMap>
    </LoadScript>
  );
};

import React from "react";
import { DashboardValueCard } from "./DashboardValueCard";
import { KpiName } from "../../Core/Kpi/KpiName";
import { KpiDescription } from "../../Core/Kpi/KpiDescription";
import { KpiIcon } from "../../Core/Kpi/KpiIcon";
import { subDays } from "date-fns";
import { fromYmdString, toYmdString } from "../../Core/DateTime/ymdFunctions";
import { KpiTrend } from "../../Core/Kpi/KpiTrend";
import { KpiValue } from "../../Core/Kpi/KpiValue";
import { Aggregation, Kpi } from "../../../generated/graphql";
import { gql } from "@apollo/client";
import { useGetAdjustedKpiForTrendCardQuery } from "../Hook/useGetAdjustedKpiForTrendCardQuery";
import { useAggregatedChartData } from "../Hook/useAggregatedChartData";
import { DashboardValueCardChart } from "./DashboardValueCardChart";

interface Props {
  storeIds: number[];
  kpi: Kpi;
  startDate: string;
  endDate: string;
  aggregation: Aggregation;
}

export const GET_KPI = gql`
  query GetKpiForTrendCard(
    $kpi: Kpi!
    $start: String
    $end: String
    $aggregation: Aggregation!
    $baselineStart: String
    $baselineEnd: String
    $storeIds: [Int!]
  ) {
    total: countingDataTotal(
      kpi: $kpi
      start: $start
      end: $end
      storeIds: $storeIds
    )
    totalBaseline: countingDataTotal(
      kpi: $kpi
      start: $baselineStart
      end: $baselineEnd
      storeIds: $storeIds
    )
    historicalData: kpiData(
      kpi: $kpi
      aggregation: $aggregation
      start: $start
      end: $end
      storeIds: $storeIds
    ) {
      start
      end
      value
    }
  }
`;

export const KpiTrendCard: React.FunctionComponent<Props> = (props) => {
  const { kpi, startDate, endDate, aggregation } = props;
  const baselineStartDate = toYmdString(subDays(fromYmdString(startDate), 7));
  const baselineEndDate = toYmdString(subDays(fromYmdString(endDate), 7));

  const { data, loading } = useGetAdjustedKpiForTrendCardQuery({
    variables: {
      kpi: kpi,
      start: startDate + "T00:00:00",
      end: endDate + "T23:59:59",
      aggregation: aggregation,
      baselineStart: baselineStartDate + "T00:00:00",
      baselineEnd: baselineEndDate + "T23:59:59",
      storeIds: props.storeIds,
    },
  });

  const chartData = useAggregatedChartData({
    aggregation: aggregation,
    historicalValues: [{ kpiData: data.historicalData }],
  });

  return (
    <DashboardValueCard
      loading={loading}
      value={<KpiValue kpi={kpi} value={data.total} />}
      secondaryValue={
        <KpiTrend
          kpi={kpi}
          value={data.total}
          baselineValue={data.totalBaseline}
        />
      }
      title={<KpiName kpi={kpi} />}
      icon={<KpiIcon kpi={kpi} />}
      description={<KpiDescription kpi={kpi} />}
      bottomMedia={<DashboardValueCardChart data={chartData} />}
    />
  );
};

import React from "react";
import { Kpi } from "../../../generated/graphql";

interface Props {
  kpi: Kpi;
  value: any;
}
const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 1,
});

const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "EUR",
});

export const KpiValue: React.FunctionComponent<Props> = ({ kpi, value }) => {
  switch (kpi) {
    case Kpi.Visitor:
    case Kpi.Customer:
    case Kpi.Pedestrian:
      return <>{formatter.format(value)}</>;
    case Kpi.ConversionRate:
      return <>{formatter.format(value / 100)}%</>;
    case Kpi.Turnover:
      return <>{currencyFormatter.format(value)}</>;
  }
  return <>{value}</>;
};

import { useConnectApiFetch } from "../../Hook/useConnectApiFetch";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { useAuthenticationInformation } from "./useAuthenticationInformation";

export const useLogout = () => {
  const fetch = useConnectApiFetch();
  const queryClient = useQueryClient();
  const { user } = useAuthenticationInformation();
  const doLogout = useCallback(async () => {
    if (user) {
      await fetch(`${user.id}/logout`, {
        method: "GET",
        credentials: "include",
      });
    }

    queryClient.cancelQueries();
    queryClient.invalidateQueries();
  }, [fetch, queryClient, user]);

  const mutation = useMutation(doLogout);

  return {
    doLogout: mutation.mutate,
    isError: mutation.isError,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { Aggregation, Comparison, Kpi, Period } from "../../generated/graphql";
import { HeatmapCard } from "./Component/HeatMapCard";
import { format } from "date-fns";
import { Headline } from "./Component/Headline";
import { OccupancyRankingCard } from "./Component/OccupancyRankingCard";
import { KpiCardV2 } from "./Component/KpiCardV2";
import { PeriodSelectionBar } from "./Component/PeriodSelectionBar";
import { PerformanceDisplay } from "./Component/PerformanceDisplay";

interface Props {}

export const DashboardNew: React.FunctionComponent<Props> = () => {
  const [period, setPeriod] = useState<Period>(Period.Daily);
  const [comparePeriod, setComparePeriod] = useState<Comparison>(
    Comparison.Day
  );
  const date = new Date();
  const dataContext = {
    storeIds: [],
    date: format(date, "yyyy-MM-dd"),
    aggregation: Aggregation.Hourly,
  };

  return (
    <>
      <Headline />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ marginBottom: 2 }}
        justifyContent={"flex-end"}
      >
        <PeriodSelectionBar
          onPeriodChange={setPeriod}
          onComparePeriodChange={setComparePeriod}
        />
      </Stack>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={4}
        spacing={4}
        columns={{ xs: 12, sm: 12, md: 12, xl: 12 }}
      >
        <Grid item xs={12} sm={12} md={8} xl={8}>
          <PerformanceDisplay endDate={date} />
        </Grid>

        <Grid container item xs={12} sm={12} md={4} xl={4}>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <KpiCardV2
              kpi={Kpi.Visitor}
              period={period}
              comparison={comparePeriod}
              date={date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <KpiCardV2
              kpi={Kpi.ConversionRate}
              period={period}
              comparison={comparePeriod}
              date={date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <KpiCardV2
              kpi={Kpi.Pedestrian}
              period={period}
              comparison={comparePeriod}
              date={date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <KpiCardV2
              kpi={Kpi.Turnover}
              period={period}
              comparison={comparePeriod}
              date={date}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Stack spacing={2} sx={{ margin: "1em" }}>
              <OccupancyRankingCard />
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8} md={8} xl={8}>
          <HeatmapCard {...dataContext} kpi={Kpi.Visitor} />
        </Grid>
      </Grid>
    </>
  );
};

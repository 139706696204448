import React from "react";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useCurrentUser } from "../../Core/Authentication/AuthenticationGuard";
import { useTranslation } from "react-i18next";

interface Props {}

export const Headline: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation("dashboard");
  return <Typography variant="h2">{t("headline")}</Typography>;
};

export const HeadlineLong: React.FunctionComponent<Props> = () => {
  const isLargerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const user = useCurrentUser();
  const { t } = useTranslation("dashboard");
  return (
    <>
      {isLargerScreen ? (
        <Typography variant="h2">{t("headline")}</Typography>
      ) : null}
      {user.firstname.trim() === "" ? (
        <Typography variant="h2">{t("greeting_without_firstname")}</Typography>
      ) : (
        <Typography variant="h2">
          {t("greeting", { firstname: user.firstname })}
        </Typography>
      )}
    </>
  );
};

import React, { useContext } from "react";
import {
  CurrentUser,
  useAuthenticationInformation,
} from "./Hook/useAuthenticationInformation";

const UserContext = React.createContext<CurrentUser | undefined>(undefined);

interface Props {
  loginForm: React.ReactElement;
  children: React.ReactElement;
}

/**
 * Only renders it's children if the user is logged in.
 * Otherwise, the given LoginForm will be rendered.
 */
export const AuthenticationGuard: React.FunctionComponent<Props> = ({
  children,
  loginForm,
}) => {
  const { isLoading, user, namedPermissions } = useAuthenticationInformation();

  if (isLoading) {
    return <div>Authenticating…</div>;
  }

  if (!user) {
    return loginForm;
  }

  if (
    !namedPermissions ||
    !namedPermissions.any ||
    !namedPermissions.any.includes("graphql_api.view")
  ) {
    return <div>Not permitted</div>;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const MockAuthenticationGuard: React.FunctionComponent = ({
  children,
}) => {
  return (
    <UserContext.Provider
      value={{
        id: "666",
        username: "mockUser",
        firstname: "Mock",
        lastname: "User",
        locale: "de",
        phone: "",
        job_title: "",
        email: "mockUser@mock-domain.de",
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useCurrentUser(): CurrentUser {
  const user: CurrentUser | undefined = useContext(UserContext);

  if (!user) {
    throw new Error(
      "Internal Error - no current user in Context, did you forget to wrap the application in <AuthenticationGuard />?"
    );
  }

  return user;
}

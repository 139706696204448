import React from "react";
import { Box, TextField } from "@mui/material";
import { FillLevelData } from "../Dashboard/FillLevelScreen";
import { useTranslation } from "react-i18next";
import { EditorPanelContainer } from "./EditorPanelContainer";

interface Props {
  data: FillLevelData;
  onDataChanged: (data: FillLevelData) => void;
}

export const LayoutPreviewDataSelection: React.FunctionComponent<Props> = ({
  data,
  onDataChanged,
}) => {
  const { t } = useTranslation("fillLevel");

  const handleChange = (field: keyof FillLevelData, value: number) => {
    const newData = { ...data };
    newData[field] = value;
    onDataChanged(newData);
  };

  return (
    <EditorPanelContainer headline={t("choose_demo_data")}>
      <Box component={"form"}>
        <TextField
          margin={"normal"}
          fullWidth
          type={"number"}
          label={t("metric_type_current")}
          value={data.current}
          onChange={(event) =>
            handleChange("current", parseInt(event.target.value, 10))
          }
        />
        <TextField
          margin={"normal"}
          fullWidth
          type={"number"}
          label={t("metric_type_limit")}
          value={data.limit}
          onChange={(event) =>
            handleChange("limit", parseInt(event.target.value, 10))
          }
        />
      </Box>
    </EditorPanelContainer>
  );
};

import React, { ComponentProps, ReactNode } from "react";
import {
  FormControl,
  FormControlLabel,
  Switch as MuiSwitch,
} from "@mui/material";

interface Props {
  label: ReactNode;
  labelPlacement?: ComponentProps<typeof FormControlLabel>["labelPlacement"];
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  checked: boolean;
  name?: string;
}

export const Switch: React.FunctionComponent<Props> = ({
  label,
  labelPlacement = "start",
  onChange,
  checked,
  name,
}) => {
  return (
    <FormControl fullWidth margin={"normal"}>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <MuiSwitch onChange={onChange} checked={checked} name={name} />
        }
        label={label}
      />
    </FormControl>
  );
};

export const adjustZeroValues = <NumericalValue extends { value: number }>(
  numericalValues: NumericalValue[]
): NumericalValue[] => {
  const newValues = [...numericalValues];

  while (newValues.length > 0 && newValues[0].value === 0) {
    newValues.shift();
  }

  while (newValues.length > 0 && newValues[newValues.length - 1].value === 0) {
    newValues.pop();
  }

  return newValues;
};

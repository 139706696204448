import * as Apollo from "@apollo/client";
import {
  GetKpiForHeatmapQuery,
  GetKpiForHeatmapQueryHookResult,
  GetKpiForHeatmapQueryVariables,
  KpiValue,
  useGetKpiForHeatmapQuery,
} from "../../../generated/graphql";

type DailyInterval = {
  start: number;
  end: number;
};

function getEarliestAndLastHourWithValue(kpiValues: KpiValue[]): DailyInterval {
  const hoursWithPositiveValues: number[] = kpiValues
    .filter((kpiValue) => kpiValue.value > 0)
    .map((kpiValue) => new Date(kpiValue.start).getHours());

  return hoursWithPositiveValues.length > 0
    ? {
        start: Math.min(...hoursWithPositiveValues),
        end: Math.max(...hoursWithPositiveValues),
      }
    : { start: 0, end: 23 };
}

type GetFilteredKpiForHeatmapQueryResult = Omit<
  GetKpiForHeatmapQueryHookResult,
  "data"
> & {
  data: GetKpiForHeatmapQuery;
};

export const useGetFilteredKpiForHeatmapQuery = (
  baseOptions: Apollo.QueryHookOptions<
    GetKpiForHeatmapQuery,
    GetKpiForHeatmapQueryVariables
  >
): GetFilteredKpiForHeatmapQueryResult => {
  const { data, ...rest } = useGetKpiForHeatmapQuery(baseOptions);

  const dailyInterval = getEarliestAndLastHourWithValue(data?.kpiData || []);

  return {
    ...rest,
    data: {
      ...data,
      kpiData:
        data?.kpiData.filter(
          (kpiValue) =>
            dailyInterval.start <= new Date(kpiValue.start).getHours() &&
            new Date(kpiValue.start).getHours() <= dailyInterval.end
        ) || [],
    },
  };
};

import React from "react";
import { useTranslation } from "react-i18next";
import { availableNamespaces } from "../../i18next";

interface Props {
  namespaces?: string[];
  loadingIndicator?: React.ReactNode;
}

/**
 * Will only render it's children once the given namespaces are loaded.
 * Defaults to waiting for all namespaces.
 */
export const TranslationGuard: React.FunctionComponent<Props> = ({
  children,
  namespaces = availableNamespaces,
  loadingIndicator,
}) => {
  const { ready } = useTranslation("loginForm");

  if (ready) {
    return <>{children}</>;
  }

  return loadingIndicator ? <>{loadingIndicator}</> : null;
};

import { useDateFnsLocale } from "../LocalizationProvider";
import { format } from "date-fns";

export const useDateFormat = () => {
  const locale = useDateFnsLocale();

  return (
    date: Date | number,
    dateFormat: string,
    options?: Parameters<typeof format>[2]
  ) => {
    return format(date, dateFormat, { locale, ...options });
  };
};

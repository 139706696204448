import React, { useState } from "react";
import { MultiStoreSelectCombobox } from "../Core/StoreSelection/MultiStoreSelectCombobox";
import { Box, Grid, Stack } from "@mui/material";
import { DatePicker } from "../../ui-component-library/Form/Field/DatePicker";
import { format } from "date-fns";
import { KpiTrendCard } from "./Component/KpiTrendCard";
import { LocationIndexCard } from "./Component/LocationIndexCard";
import { Aggregation, Kpi } from "../../generated/graphql";
import { HeatmapCard } from "./Component/HeatMapCard";
import { Headline } from "./Component/Headline";

interface Props {}

export const Dashboard: React.FunctionComponent<Props> = () => {
  const [storeIds, setStoreIds] = useState<number[]>([]);
  const [ymd, setYmd] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const dataContext = {
    storeIds,
    startDate: ymd,
    endDate: ymd,
    aggregation: Aggregation.Hourly,
  };

  return (
    <>
      <Headline />
      <Box sx={{ margin: 1 }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
          sx={{ marginBottom: 2 }}
        >
          <DatePicker ymdValue={ymd} onChange={setYmd} />
          <Box sx={{ marginY: 1 }}>
            <MultiStoreSelectCombobox onChange={setStoreIds} />
          </Box>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={4}
          spacing={4}
          columns={{ xs: 2, sm: 8, md: 12, xl: 12 }}
        >
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <KpiTrendCard {...dataContext} kpi={Kpi.Visitor} />
          </Grid>
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <KpiTrendCard {...dataContext} kpi={Kpi.Customer} />
          </Grid>
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <KpiTrendCard {...dataContext} kpi={Kpi.ConversionRate} />
          </Grid>
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <KpiTrendCard {...dataContext} kpi={Kpi.Turnover} />
          </Grid>
          {/*<Grid item xs={2} sm={4} md={4} xl={3}>*/}
          {/*  <KpiCard {...dataContext} kpi="FillLevel" />*/}
          {/*</Grid>*/}
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <KpiTrendCard {...dataContext} kpi={Kpi.Pedestrian} />
          </Grid>
          <Grid item xs={2} sm={8} md={6} xl={3}>
            <HeatmapCard
              date={dataContext.endDate}
              storeIds={dataContext.storeIds}
              kpi={Kpi.Visitor}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} xl={3}>
            <LocationIndexCard {...dataContext} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

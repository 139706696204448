import { InfoWindow, InfoWindowProps } from "@react-google-maps/api";
import { Store } from "../../../generated/graphql";
import { Button, Stack, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface StoreInfoWindowProps extends InfoWindowProps {
  store: Store;
}

export const StoreInfoWindow: React.FunctionComponent<StoreInfoWindowProps> = ({
  store,
  ...restProps
}) => {
  const navigate = useNavigate();

  if (!store.geoLocation) {
    return null;
  }

  return (
    <InfoWindow
      position={{
        lat: store.geoLocation.latitude,
        lng: store.geoLocation.longitude,
      }}
      {...restProps}
    >
      <Stack>
        <Typography variant={"subtitle2"}>{store.title}</Typography>
        <Typography variant={"body2"}>{store.address?.street1}</Typography>
        <Typography variant={"body2"}>{store.address?.street2}</Typography>
        <Typography variant={"body2"}>
          {store.address?.zip} {store.address?.city}
        </Typography>
        <Typography variant={"body2"}>{store.address?.country}</Typography>
        <Button
          startIcon={<OpenInNew />}
          onClick={() => navigate(`../store-details/${store.id}`)}
        >
          Details
        </Button>
      </Stack>
    </InfoWindow>
  );
};

import { LocationsContent } from "./Component/LocationsContent";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { StoreDetails } from "./Component/StoreDetails";

export const Locations: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"all"} />} />
      <Route path={"all"} element={<LocationsContent />} />
      <Route path={"store-details/:storeId"} element={<StoreDetails />} />
    </Routes>
  );
};

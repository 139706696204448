import React from "react";
import { FillLevelScreen, LayoutOptions } from "./Dashboard/FillLevelScreen";
import { MetricType } from "./Dashboard/MetricItem";
import { Box } from "@mui/material";
import { useWindowSize } from "react-use";
import { useTranslation } from "react-i18next";

interface Props {}

export const FillLevelDashboard: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation("fillLevel");
  const layoutOptions: LayoutOptions = {
    variant: "brand",
    stateOptions: {
      ok: {
        greetingText: t("greeting_text_ok_1"),
        greetingSecondaryText: t("greeting_secondary_text_ok_1"),
        icon: "icon_ok_1",
      },
      limit_exceeded: {
        greetingText: t("greeting_text_limit_exceeded_1"),
        greetingSecondaryText: t("greeting_secondary_text_limit_exceeded_1"),
        icon: "icon_limit_exceeded_1",
      },
      offline: {
        greetingText: t("greeting_text_offline_1"),
        greetingSecondaryText: t("greeting_secondary_text_offline_1"),
        icon: "icon_offline_1",
      },
    },
    brand: {
      name: "Crosscan GmbH",
      slogan: "Digitale Transformation für den Einzelhandel",
      color: "#ffffff",
    },
  };
  const layoutState = "ok";
  const data = {
    current: 23,
    limit: 28,
    waitingTimeSeconds: 134,
  };
  const metricTypes: MetricType[] = [
    "current",
    "available",
    "limit",
    "occupancy",
  ];

  const { width, height } = useWindowSize();
  const orientation = width > height ? "landscape" : "portrait";

  return (
    <Box sx={{ height: "100vh", width: "100vw" }}>
      <FillLevelScreen
        data={data}
        orientation={orientation}
        metricTypes={metricTypes}
        state={layoutState}
        options={layoutOptions}
      />
    </Box>
  );
};

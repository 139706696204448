import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

interface LabeledProgressBarProps {
  label: string;
  value: number;
}

export const LabeledProgressBar: React.FunctionComponent<LabeledProgressBarProps> =
  ({ label, value }) => {
    const theme = useTheme();
    const colorPalette = value < 80 ? "success" : "error";
    const color = theme.palette[colorPalette].main;

    return (
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          px: 1,
          backgroundColor: theme.palette.grey["100"],
        }}
      >
        {value > 0 && (
          <span
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              display: "block",
              backgroundColor: color,
              borderRight: `2px solid ${color}`,
              transform: `translateX(${Math.min(100, value) - 100}%)`,
              opacity: 0.3,
            }}
          />
        )}
        {value > 0 && (
          <span
            style={{
              position: "absolute",
              left: `calc(${value}% - 2px)`,
              top: 0,
              width: "2px",
              height: "100%",
              display: "block",
              borderRight: `2px solid ${color}`,
            }}
          />
        )}
        <Typography
          variant={"body2"}
          noWrap={true}
          style={{ position: "relative" }}
          title={label}
        >
          {label}
        </Typography>
      </Box>
    );
  };

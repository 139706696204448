import { FormControl, TextField as MuiTextField } from "@mui/material";
import { FieldProps } from "./FieldProps";

export const TextField: React.FunctionComponent<FieldProps> = ({
  label,
  onChange,
  onBlur,
  value,
  name,
}) => {
  return (
    <FormControl fullWidth margin={"normal"}>
      <MuiTextField
        label={label}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
      />
    </FormControl>
  );
};

import React, { useContext } from "react";

type CrosscanConnectEnvironment = {
  baseUrl: string;
};

const EnvironmentContext: React.Context<
  CrosscanConnectEnvironment | undefined
> = React.createContext<undefined | CrosscanConnectEnvironment>(undefined);

export const EnvironmentProvider: React.FunctionComponent<{
  environment: CrosscanConnectEnvironment;
}> = ({ environment, children }) => {
  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export function useEnvironment(): CrosscanConnectEnvironment {
  const env: CrosscanConnectEnvironment | undefined =
    useContext(EnvironmentContext);

  if (!env) {
    throw new Error(
      "Internal Error - no CrosscanConnectEnvironment in Context"
    );
  }

  return env;
}

export function useBaseUrl(): string {
  return useEnvironment().baseUrl;
}

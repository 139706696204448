import { Tab, TabProps, Tabs } from "@mui/material";
import { NavButton } from "./NavButton";
import { Devices, Groups, Map } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavTab: React.FunctionComponent<TabProps> = (props) => {
  return <Tab {...props} sx={{ borderRight: "1px solid #000" }} />;
};

export enum AdminMenuEntry {
  LOCATIONS = "locations",
  DEVICES = "devices",
  USERS = "users",
}

interface AdminMenuProps {
  selected: AdminMenuEntry;
}

export const AdminMenu: React.FunctionComponent<AdminMenuProps> = ({
  selected,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("administration");

  return (
    <Tabs
      value={selected}
      variant={"fullWidth"}
      sx={{
        borderBottom: "1px solid",
        flexGrow: 0,
        flexShrink: 1,
        marginRight: "-1px",
      }}
    >
      <NavTab
        label={<NavButton icon={<Map />} label={t("MENU_LOCATIONS")} />}
        value={AdminMenuEntry.LOCATIONS}
        onClick={() => navigate("../locations")}
      />
      <NavTab
        label={<NavButton icon={<Devices />} label={t("MENU_DEVICES")} />}
        value={AdminMenuEntry.DEVICES}
        onClick={() => navigate("../devices")}
      />
      <NavTab
        label={<NavButton icon={<Groups />} label={t("MENU_USERS")} />}
        value={AdminMenuEntry.USERS}
        onClick={() => navigate("../users")}
      />
    </Tabs>
  );
};

import React from "react";
import { Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { FillLevelMetric } from "./MetricItem";

type MetricType = FillLevelMetric["type"];

type IconProps = {
  fontSize: "inherit";
  sx: object;
  htmlColor: string;
};

interface Props {
  type: MetricType;
  color: string;
}

const getIconForType = (type: MetricType, color: string) => {
  const iconProps: IconProps = {
    sx: { display: "block" },
    fontSize: "inherit",
    htmlColor: color,
  };

  switch (type) {
    case "current":
      return <PersonIcon {...iconProps} />;
    case "available":
      return <PersonAddAlt1Icon {...iconProps} />;
    case "limit":
      return <PeopleAltIcon {...iconProps} />;
    case "occupancy":
      return <TimelapseIcon {...iconProps} />;
  }
};

export const MetricIcon: React.FunctionComponent<Props> = ({ type, color }) => {
  return (
    <Box sx={{ fontSize: "65px", lineHeight: 1, marginRight: "2px" }}>
      {getIconForType(type, color)}
    </Box>
  );
};

import React, { useState } from "react";
import ChartComponent from "react-chartjs-2";
import { Context } from "chartjs-plugin-datalabels";
import { KpiValue } from "../../../generated/graphql";
import { useTheme } from "@mui/material";
import { compareAsc } from "date-fns";

type HeatmapDataPoint = {
  x: string;
  y: string;
  v: number;
};

type Props = {
  kpiValues: KpiValue[];
};

function hourlyKpiValueToHeatmapDataPoint(
  kpiValue: KpiValue
): HeatmapDataPoint {
  const date = new Date(kpiValue.start);
  return {
    x: date.toLocaleDateString(),
    y: date.getHours() + ":00",
    v: kpiValue.value,
  };
}

function getXAxisLabels(kpiValues: KpiValue[]): string[] {
  const dateStrings = kpiValues.map((kpiValue) =>
    new Date(kpiValue.start).toDateString()
  );

  return dateStrings
    .filter((item, index) => dateStrings.indexOf(item) === index)
    .map((dateString) => new Date(dateString))
    .sort(compareAsc)
    .map((date) => date.toLocaleDateString());
}

function getYAxisLabels(kpiValues: KpiValue[]): string[] {
  const hours = kpiValues.map((kpiValue) =>
    new Date(kpiValue.start).getHours()
  );

  return hours
    .filter((item, index) => hours.indexOf(item) === index)
    .sort((a, b) => a - b)
    .map((hour) => hour + ":00");
}

function getMaxValue(kpiValues: KpiValue[]): number {
  return Math.max(0, ...kpiValues.map((kpiValue) => kpiValue.value));
}

export const HeatmapChart: React.FunctionComponent<Props> = ({ kpiValues }) => {
  const theme = useTheme();
  const xAxisLabels = getXAxisLabels(kpiValues);
  const yAxisLabels = getYAxisLabels(kpiValues);
  const maxValue = getMaxValue(kpiValues);
  const heatmapData = kpiValues.map(hourlyKpiValueToHeatmapDataPoint);
  const [showLabels, setShowLabels] = useState<boolean>(false);

  const scales = {
    x: {
      type: "category",
      labels: xAxisLabels,
      grid: { display: false },
      position: "top",
      ticks: { maxRotation: 0 },
    },
    y: {
      type: "category",
      labels: yAxisLabels,
      grid: { display: false },
      offset: true,
      reverse: false,
    },
  };

  const dataLabelConfig = {
    anchor: "left",
    display: showLabels,
    align: function (ctx: Context) {
      const chartArea = ctx.chart.chartArea;
      const width = chartArea ? chartArea.width / xAxisLabels.length : 5;
      const height = chartArea ? chartArea.height / yAxisLabels.length : 5;

      // for some reason, 3 degrees offset are a good fit
      return 3 + (45 * height) / width;
    },
    offset: function (ctx: Context) {
      const chartArea = ctx.chart.chartArea;
      const width = chartArea ? chartArea.width / xAxisLabels.length : 5;
      const height = chartArea ? chartArea.height / yAxisLabels.length : 5;
      const dataset = ctx.dataset || { data: [] };
      const item: any = dataset.data[ctx.dataIndex];
      const label = item.v.toString();
      const context = ctx.chart.canvas.getContext("2d");

      if (context === null) {
        return undefined;
      }

      const labelWidth = Math.round(context.measureText(label).width);

      return Math.hypot(width - labelWidth - 5, height - 16) / 2;
    },
    color: function (ctx: any) {
      const datapoint: HeatmapDataPoint = ctx.dataset.data[ctx.dataIndex];
      const value = datapoint.v;

      return value > 0.5 * maxValue ? "#FFFFFF" : "#333333";
    },
    formatter: function (value: HeatmapDataPoint) {
      return Math.round(value.v);
    },
  };

  const chartData = {
    datasets: [
      {
        data: heatmapData,
        borderWidth: 0,
        borderColor: "#FFFFFF",
        backgroundColor: function (ctx: any) {
          if (ctx.dataIndex === undefined || maxValue === 0) {
            return "#FFFFFFFF";
          }

          const datapoint: HeatmapDataPoint = ctx.dataset.data[ctx.dataIndex];
          const alpha = Math.round((datapoint.v / maxValue) * 255)
            .toString(16)
            .padStart(2, "0");

          return theme.palette.primary.main + alpha;
        },
        width: function (ctx: Context) {
          const a = ctx.chart.chartArea;
          return a ? a.width / xAxisLabels.length - 2 : 5;
        },
        height: function (ctx: Context) {
          const a = ctx.chart.chartArea;
          return a ? a.height / yAxisLabels.length - 2 : 5;
        },
        datalabels: dataLabelConfig,
      },
    ],
  };

  const options = {
    animation: {
      duration: 0,
    },
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          title(context: any) {
            const v = context[0].dataset.data[context[0].dataIndex];
            return v.x + ", " + v.y;
          },
          label(context: any) {
            const v = context.dataset.data[context.dataIndex];
            return [v.v];
          },
        },
      },
    },
    scales: scales,
  };

  return (
    <ChartComponent
      onMouseEnter={() => setShowLabels(true)}
      onMouseLeave={() => setShowLabels(false)}
      type={"matrix"}
      data={chartData}
      options={options}
    />
  );
};

import * as Apollo from "@apollo/client";
import {
  GetKpiForTrendCardQuery,
  GetKpiForTrendCardQueryHookResult,
  GetKpiForTrendCardQueryVariables,
  useGetKpiForTrendCardQuery,
} from "../../../generated/graphql";
import { adjustZeroValues } from "./adjustZeroValues";

type GetAdjustedKpiForTrendCardQueryResult = Omit<
  GetKpiForTrendCardQueryHookResult,
  "data"
> & {
  data: GetKpiForTrendCardQuery;
};

export const useGetAdjustedKpiForTrendCardQuery = (
  baseOptions: Apollo.QueryHookOptions<
    GetKpiForTrendCardQuery,
    GetKpiForTrendCardQueryVariables
  >
): GetAdjustedKpiForTrendCardQueryResult => {
  const { data, ...rest } = useGetKpiForTrendCardQuery(baseOptions);

  return {
    ...rest,
    data: data
      ? {
          total: data.total,
          totalBaseline: data.totalBaseline,
          historicalData: adjustZeroValues(data.historicalData),
        }
      : {
          total: 0,
          totalBaseline: 0,
          historicalData: [],
        },
  };
};

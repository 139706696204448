import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { LayoutPreview } from "./LayoutEditor/LayoutPreview";
import { LayoutPreviewMetricSelection } from "./LayoutEditor/LayoutPreviewMetricSelection";
import {
  FillLevelData,
  LayoutOptions,
  LayoutState,
} from "./Dashboard/FillLevelScreen";
import { LayoutPreviewDataSelection } from "./LayoutEditor/LayoutPreviewDataSelection";
import { LayoutStateSwitch } from "./LayoutEditor/LayoutStateSwitch";
import { MetricType } from "./Dashboard/MetricItem";
import { Formik } from "formik";
import { BrandInformationPanel } from "./LayoutEditor/BrandInformationPanel";
import { LayoutOptionsPanel } from "./LayoutEditor/LayoutOptionsPanel";

interface Props {}

type FormValues = Pick<LayoutOptions, "brand" | "variant"> & {
  stateOptions: {
    [key in LayoutState]: {
      greetingText: string;
      icon: string;
      greetingSecondaryText: string;
    };
  };
};

export const FillLevelLayoutEditor: React.FunctionComponent<Props> = () => {
  const [layoutState, setLayoutState] = useState<LayoutState>("ok");
  const [data, setData] = useState<FillLevelData>({
    current: 23,
    limit: 28,
    waitingTimeSeconds: 134,
  });
  const [metricTypes, setMetricTypes] = useState<MetricType[]>([
    "current",
    "available",
    "limit",
    "occupancy",
  ]);

  const { t } = useTranslation("fillLevel");

  const initialValues: FormValues = {
    variant: "simple",
    brand: {
      name: "Crosscan GmbH",
      slogan: "Digitale Transformation für den Einzelhandel",
      color: "#ffffff",
    },
    stateOptions: {
      ok: {
        greetingText: t("greeting_text_ok_1"),
        greetingSecondaryText: t("greeting_secondary_text_ok_1"),
        icon: "icon_ok_1",
      },
      limit_exceeded: {
        greetingText: t("greeting_text_limit_exceeded_1"),
        greetingSecondaryText: t("greeting_secondary_text_limit_exceeded_1"),
        icon: "icon_limit_exceeded_1",
      },
      offline: {
        greetingText: t("greeting_text_offline_1"),
        greetingSecondaryText: t("greeting_secondary_text_offline_1"),
        icon: "icon_offline_1",
      },
    },
  };
  return (
    <Formik onSubmit={console.log} initialValues={initialValues}>
      {({ values }) => (
        <>
          <Typography variant={"h2"} sx={{ marginLeft: 1 }}>
            {t("edit_your_layout")}
          </Typography>
          <Grid container>
            <Grid item xs={12} xl={7}>
              <Stack spacing={1} sx={{ padding: 1 }}>
                <Card>
                  <CardContent>
                    <Typography variant={"h6"}>{t("preview")}</Typography>
                    <LayoutPreview
                      data={data}
                      metricTypes={metricTypes}
                      state={layoutState}
                      options={values}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        paddingTop: 2,
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <LayoutStateSwitch
                        state={layoutState}
                        onStateChanged={setLayoutState}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <pre>Form Debug: {JSON.stringify(values, undefined, 2)}</pre>
              </Stack>
            </Grid>
            <Grid item xs={12} xl={5} sx={{ padding: 1 }}>
              <Card variant={"outlined"}>
                <CardContent>
                  <Typography variant={"h6"}>
                    {t("settings.headline")}
                  </Typography>
                  <BrandInformationPanel />
                  <LayoutOptionsPanel state="ok" />
                  <LayoutOptionsPanel state="limit_exceeded" />
                  <LayoutOptionsPanel state="offline" />
                  <LayoutPreviewMetricSelection
                    selectedMetricTypes={metricTypes}
                    onMetricSelectionChanged={setMetricTypes}
                  />
                  <LayoutPreviewDataSelection
                    data={data}
                    onDataChanged={setData}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

import { useLegacyConnectApiFormPost } from "../../Core/Hook/useConnectApiFetch";
import { useCallback } from "react";
import { useMutation } from "react-query";
import { UserData } from "../../../ui-component-library/Users/EditProfile";
import { useAuthenticationInformation } from "../../Core/Authentication/Hook/useAuthenticationInformation";

type IdentifiedUserData = UserData & { user_id: string };

const convertUserDataToStrings = (
  data: IdentifiedUserData
): Record<string, string> => {
  return {
    user_id: data.user_id,
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    locale: data.locale,
    job_title: data.job_title ?? "",
    phone: data.phone ?? "",
    cellphone: data.cellphone ?? "",
  };
};

export const useEditProfile = () => {
  const post = useLegacyConnectApiFormPost();
  const { refetch } = useAuthenticationInformation();

  const saveProfileData = useCallback(
    async (data: IdentifiedUserData) => {
      return await post(
        "userinfo/18/save_user.json",
        convertUserDataToStrings(data)
      );
    },
    [post]
  );

  const mutation = useMutation(saveProfileData, { onSettled: refetch });

  return {
    saveProfileData: mutation.mutate,
    isError: mutation.isError,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

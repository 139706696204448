import { Stack, StackProps, Typography } from "@mui/material";
import React from "react";

interface NavButtonProps extends StackProps {
  icon: JSX.Element;
  label: string;
}

export const NavButton: React.FunctionComponent<NavButtonProps> = ({
  icon,
  label,
  ...stackProps
}) => {
  return (
    <Stack
      alignItems={"center"}
      alignContent={"center"}
      spacing={1}
      {...stackProps}
    >
      {icon}
      <Typography>{label}</Typography>
    </Stack>
  );
};

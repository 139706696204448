import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LayoutState } from "../Dashboard/FillLevelScreen";
import {
  getAvailableIcons,
  IndicatorIcon,
} from "../../Dashboard/IndicatorIcon";

interface Props {
  state: LayoutState;
  value: string;
  onChange: any;
  name: string;
}

export const IndicatorIconSelection: React.FunctionComponent<Props> = ({
  state,
  value,
  name,
  onChange,
}) => {
  const { t } = useTranslation("fillLevel");
  return (
    <FormControl margin={"normal"} fullWidth>
      <InputLabel>{t("layout_icon")}</InputLabel>
      <Select
        value={value}
        label={t("layout_icon")}
        onChange={onChange}
        name={name}
      >
        {getAvailableIcons(state).map((icon) => (
          <MenuItem key={icon} value={icon}>
            <IndicatorIcon color={"inherit"} icon={icon} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Aggregation {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum Comparison {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type CoreMutation = {
  __typename?: 'CoreMutation';
  addStore?: Maybe<Store>;
  dummy?: Maybe<Scalars['String']>;
};


export type CoreMutationAddStoreArgs = {
  title: Scalars['String'];
};

export type CoreQuery = {
  __typename?: 'CoreQuery';
  countingDataHourly: Array<HourlyKpiValue>;
  countingDataTotal: Scalars['Float'];
  countingDataTotalByPeriod: Scalars['Float'];
  dummy?: Maybe<Scalars['String']>;
  kpiData: Array<KpiValue>;
  kpiDataPerStore?: Maybe<Array<StoreGroupedKpiData>>;
  occupancy?: Maybe<Array<OccupancyData>>;
  storeGroups: Array<StoreGroup>;
  storeLocationIndex?: Maybe<Array<Maybe<StoreLocationIndex>>>;
  stores: Array<Store>;
};


export type CoreQueryCountingDataHourlyArgs = {
  end?: Maybe<Scalars['String']>;
  installedOnly?: Maybe<Scalars['Boolean']>;
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type CoreQueryCountingDataTotalArgs = {
  end?: Maybe<Scalars['String']>;
  installedOnly?: Maybe<Scalars['Boolean']>;
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type CoreQueryCountingDataTotalByPeriodArgs = {
  comparison?: Maybe<Comparison>;
  date?: Maybe<Scalars['String']>;
  installedOnly?: Maybe<Scalars['Boolean']>;
  kpi: Kpi;
  period: Period;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type CoreQueryKpiDataArgs = {
  aggregation: Aggregation;
  end?: Maybe<Scalars['String']>;
  installedOnly?: Maybe<Scalars['Boolean']>;
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type CoreQueryKpiDataPerStoreArgs = {
  aggregation: Aggregation;
  end?: Maybe<Scalars['String']>;
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type CoreQueryOccupancyArgs = {
  enabledOnly?: Maybe<Scalars['Boolean']>;
  storeIds?: Maybe<Array<Scalars['Int']>>;
};


export type CoreQueryStoreLocationIndexArgs = {
  dateYmd?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  untilHour?: Maybe<Scalars['String']>;
};


export type CoreQueryStoresArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type HourlyKpiValue = {
  __typename?: 'HourlyKpiValue';
  date: Scalars['String'];
  hour: Scalars['Int'];
  value: Scalars['Float'];
};

export enum Kpi {
  ConversionRate = 'CONVERSION_RATE',
  Customer = 'CUSTOMER',
  Pedestrian = 'PEDESTRIAN',
  Turnover = 'TURNOVER',
  Visitor = 'VISITOR'
}

export type KpiValue = {
  __typename?: 'KpiValue';
  end: Scalars['String'];
  start: Scalars['String'];
  value: Scalars['Float'];
};

export type OccupancyData = {
  __typename?: 'OccupancyData';
  currentValue: Scalars['Int'];
  limit: Scalars['Int'];
  storeId: Scalars['Int'];
  storeTitle: Scalars['String'];
};

export enum Period {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type Store = {
  __typename?: 'Store';
  address?: Maybe<StoreAddress>;
  geoLocation?: Maybe<GeoLocation>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type StoreAddress = {
  __typename?: 'StoreAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  children?: Maybe<Array<StoreGroup>>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type StoreGroupedKpiData = {
  __typename?: 'StoreGroupedKpiData';
  kpiData: Array<KpiValue>;
  storeId?: Maybe<Scalars['Int']>;
};

export type StoreLocationIndex = {
  __typename?: 'StoreLocationIndex';
  locationIndex?: Maybe<Scalars['Float']>;
  storeId?: Maybe<Scalars['Int']>;
  storeIndex?: Maybe<Scalars['Float']>;
  storeTitle?: Maybe<Scalars['String']>;
};

export type StoreGroupFragment = { __typename?: 'StoreGroup', id: number, title: string };

export type GetAdministrationLocationStoreGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdministrationLocationStoreGroupsQuery = { __typename?: 'CoreQuery', storeGroups: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string, children?: Array<{ __typename?: 'StoreGroup', id: number, title: string }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> };

export type GetAdministrationLocationStoresQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
}>;


export type GetAdministrationLocationStoresQuery = { __typename?: 'CoreQuery', stores: Array<{ __typename?: 'Store', id: number, title: string, address?: { __typename?: 'StoreAddress', street1?: string | null | undefined, street2?: string | null | undefined, zip?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined } | null | undefined, geoLocation?: { __typename?: 'GeoLocation', latitude: number, longitude: number } | null | undefined }> };

export type GetStoresQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
}>;


export type GetStoresQuery = { __typename?: 'CoreQuery', stores: Array<{ __typename?: 'Store', id: number, title: string }> };

export type GetKpiForHeatmapQueryVariables = Exact<{
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetKpiForHeatmapQuery = { __typename?: 'CoreQuery', kpiData: Array<{ __typename?: 'KpiValue', start: string, end: string, value: number }> };

export type GetKpiForCardQueryVariables = Exact<{
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  aggregation: Aggregation;
  storeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetKpiForCardQuery = { __typename?: 'CoreQuery', total: number, historicalData: Array<{ __typename?: 'KpiValue', start: string, end: string, value: number }> };

export type GetKpiForCardV2QueryVariables = Exact<{
  kpi: Kpi;
  period: Period;
  comparison: Comparison;
  date?: Maybe<Scalars['String']>;
}>;


export type GetKpiForCardV2Query = { __typename?: 'CoreQuery', total: number, totalPrevious: number };

export type GetKpiForTrendCardQueryVariables = Exact<{
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  aggregation: Aggregation;
  baselineStart?: Maybe<Scalars['String']>;
  baselineEnd?: Maybe<Scalars['String']>;
  storeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetKpiForTrendCardQuery = { __typename?: 'CoreQuery', total: number, totalBaseline: number, historicalData: Array<{ __typename?: 'KpiValue', start: string, end: string, value: number }> };

export type GetLocationIndexQueryVariables = Exact<{
  storeIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  dateYmd?: Maybe<Scalars['String']>;
}>;


export type GetLocationIndexQuery = { __typename?: 'CoreQuery', storeLocationIndex?: Array<{ __typename?: 'StoreLocationIndex', storeId?: number | null | undefined, storeTitle?: string | null | undefined, locationIndex?: number | null | undefined, storeIndex?: number | null | undefined } | null | undefined> | null | undefined };

export type GetOccupancyForStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOccupancyForStoresQuery = { __typename?: 'CoreQuery', occupancy?: Array<{ __typename?: 'OccupancyData', storeId: number, storeTitle: string, currentValue: number, limit: number }> | null | undefined };

export type GetKpiForPerformanceChartQueryVariables = Exact<{
  kpi: Kpi;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  aggregation: Aggregation;
  storeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetKpiForPerformanceChartQuery = { __typename?: 'CoreQuery', data?: Array<{ __typename?: 'StoreGroupedKpiData', storeId?: number | null | undefined, kpiData: Array<{ __typename?: 'KpiValue', start: string, end: string, value: number }> }> | null | undefined };

export type GetStoresByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetStoresByIdsQuery = { __typename?: 'CoreQuery', data: Array<{ __typename?: 'Store', id: number, title: string }> };

export const StoreGroupFragmentDoc = gql`
    fragment storeGroup on StoreGroup {
  id
  title
}
    `;
export const GetAdministrationLocationStoreGroupsDocument = gql`
    query GetAdministrationLocationStoreGroups {
  storeGroups {
    ...storeGroup
    children {
      ...storeGroup
      children {
        ...storeGroup
        children {
          ...storeGroup
          children {
            ...storeGroup
            children {
              ...storeGroup
              children {
                ...storeGroup
                children {
                  ...storeGroup
                  children {
                    ...storeGroup
                    children {
                      ...storeGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${StoreGroupFragmentDoc}`;

/**
 * __useGetAdministrationLocationStoreGroupsQuery__
 *
 * To run a query within a React component, call `useGetAdministrationLocationStoreGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdministrationLocationStoreGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdministrationLocationStoreGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdministrationLocationStoreGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdministrationLocationStoreGroupsQuery, GetAdministrationLocationStoreGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdministrationLocationStoreGroupsQuery, GetAdministrationLocationStoreGroupsQueryVariables>(GetAdministrationLocationStoreGroupsDocument, options);
      }
export function useGetAdministrationLocationStoreGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdministrationLocationStoreGroupsQuery, GetAdministrationLocationStoreGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdministrationLocationStoreGroupsQuery, GetAdministrationLocationStoreGroupsQueryVariables>(GetAdministrationLocationStoreGroupsDocument, options);
        }
export type GetAdministrationLocationStoreGroupsQueryHookResult = ReturnType<typeof useGetAdministrationLocationStoreGroupsQuery>;
export type GetAdministrationLocationStoreGroupsLazyQueryHookResult = ReturnType<typeof useGetAdministrationLocationStoreGroupsLazyQuery>;
export type GetAdministrationLocationStoreGroupsQueryResult = Apollo.QueryResult<GetAdministrationLocationStoreGroupsQuery, GetAdministrationLocationStoreGroupsQueryVariables>;
export const GetAdministrationLocationStoresDocument = gql`
    query GetAdministrationLocationStores($title: String) {
  stores(title: $title) {
    id
    title
    address {
      street1
      street2
      zip
      city
      country
    }
    geoLocation {
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useGetAdministrationLocationStoresQuery__
 *
 * To run a query within a React component, call `useGetAdministrationLocationStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdministrationLocationStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdministrationLocationStoresQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetAdministrationLocationStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetAdministrationLocationStoresQuery, GetAdministrationLocationStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdministrationLocationStoresQuery, GetAdministrationLocationStoresQueryVariables>(GetAdministrationLocationStoresDocument, options);
      }
export function useGetAdministrationLocationStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdministrationLocationStoresQuery, GetAdministrationLocationStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdministrationLocationStoresQuery, GetAdministrationLocationStoresQueryVariables>(GetAdministrationLocationStoresDocument, options);
        }
export type GetAdministrationLocationStoresQueryHookResult = ReturnType<typeof useGetAdministrationLocationStoresQuery>;
export type GetAdministrationLocationStoresLazyQueryHookResult = ReturnType<typeof useGetAdministrationLocationStoresLazyQuery>;
export type GetAdministrationLocationStoresQueryResult = Apollo.QueryResult<GetAdministrationLocationStoresQuery, GetAdministrationLocationStoresQueryVariables>;
export const GetStoresDocument = gql`
    query GetStores($title: String) {
  stores(title: $title) {
    id
    title
  }
}
    `;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<GetStoresQuery, GetStoresQueryVariables>;
export const GetKpiForHeatmapDocument = gql`
    query GetKpiForHeatmap($kpi: Kpi!, $start: String, $end: String, $storeIds: [Int!]) {
  kpiData(
    kpi: $kpi
    aggregation: HOURLY
    start: $start
    end: $end
    storeIds: $storeIds
  ) {
    start
    end
    value
  }
}
    `;

/**
 * __useGetKpiForHeatmapQuery__
 *
 * To run a query within a React component, call `useGetKpiForHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiForHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiForHeatmapQuery({
 *   variables: {
 *      kpi: // value for 'kpi'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useGetKpiForHeatmapQuery(baseOptions: Apollo.QueryHookOptions<GetKpiForHeatmapQuery, GetKpiForHeatmapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiForHeatmapQuery, GetKpiForHeatmapQueryVariables>(GetKpiForHeatmapDocument, options);
      }
export function useGetKpiForHeatmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiForHeatmapQuery, GetKpiForHeatmapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiForHeatmapQuery, GetKpiForHeatmapQueryVariables>(GetKpiForHeatmapDocument, options);
        }
export type GetKpiForHeatmapQueryHookResult = ReturnType<typeof useGetKpiForHeatmapQuery>;
export type GetKpiForHeatmapLazyQueryHookResult = ReturnType<typeof useGetKpiForHeatmapLazyQuery>;
export type GetKpiForHeatmapQueryResult = Apollo.QueryResult<GetKpiForHeatmapQuery, GetKpiForHeatmapQueryVariables>;
export const GetKpiForCardDocument = gql`
    query GetKpiForCard($kpi: Kpi!, $start: String, $end: String, $aggregation: Aggregation!, $storeIds: [Int!]) {
  total: countingDataTotal(
    kpi: $kpi
    start: $start
    end: $end
    storeIds: $storeIds
  )
  historicalData: kpiData(
    kpi: $kpi
    aggregation: $aggregation
    start: $start
    end: $end
    storeIds: $storeIds
  ) {
    start
    end
    value
  }
}
    `;

/**
 * __useGetKpiForCardQuery__
 *
 * To run a query within a React component, call `useGetKpiForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiForCardQuery({
 *   variables: {
 *      kpi: // value for 'kpi'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      aggregation: // value for 'aggregation'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useGetKpiForCardQuery(baseOptions: Apollo.QueryHookOptions<GetKpiForCardQuery, GetKpiForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiForCardQuery, GetKpiForCardQueryVariables>(GetKpiForCardDocument, options);
      }
export function useGetKpiForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiForCardQuery, GetKpiForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiForCardQuery, GetKpiForCardQueryVariables>(GetKpiForCardDocument, options);
        }
export type GetKpiForCardQueryHookResult = ReturnType<typeof useGetKpiForCardQuery>;
export type GetKpiForCardLazyQueryHookResult = ReturnType<typeof useGetKpiForCardLazyQuery>;
export type GetKpiForCardQueryResult = Apollo.QueryResult<GetKpiForCardQuery, GetKpiForCardQueryVariables>;
export const GetKpiForCardV2Document = gql`
    query GetKpiForCardV2($kpi: Kpi!, $period: Period!, $comparison: Comparison!, $date: String) {
  total: countingDataTotalByPeriod(kpi: $kpi, period: $period, date: $date)
  totalPrevious: countingDataTotalByPeriod(
    kpi: $kpi
    period: $period
    comparison: $comparison
    date: $date
  )
}
    `;

/**
 * __useGetKpiForCardV2Query__
 *
 * To run a query within a React component, call `useGetKpiForCardV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiForCardV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiForCardV2Query({
 *   variables: {
 *      kpi: // value for 'kpi'
 *      period: // value for 'period'
 *      comparison: // value for 'comparison'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetKpiForCardV2Query(baseOptions: Apollo.QueryHookOptions<GetKpiForCardV2Query, GetKpiForCardV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiForCardV2Query, GetKpiForCardV2QueryVariables>(GetKpiForCardV2Document, options);
      }
export function useGetKpiForCardV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiForCardV2Query, GetKpiForCardV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiForCardV2Query, GetKpiForCardV2QueryVariables>(GetKpiForCardV2Document, options);
        }
export type GetKpiForCardV2QueryHookResult = ReturnType<typeof useGetKpiForCardV2Query>;
export type GetKpiForCardV2LazyQueryHookResult = ReturnType<typeof useGetKpiForCardV2LazyQuery>;
export type GetKpiForCardV2QueryResult = Apollo.QueryResult<GetKpiForCardV2Query, GetKpiForCardV2QueryVariables>;
export const GetKpiForTrendCardDocument = gql`
    query GetKpiForTrendCard($kpi: Kpi!, $start: String, $end: String, $aggregation: Aggregation!, $baselineStart: String, $baselineEnd: String, $storeIds: [Int!]) {
  total: countingDataTotal(
    kpi: $kpi
    start: $start
    end: $end
    storeIds: $storeIds
  )
  totalBaseline: countingDataTotal(
    kpi: $kpi
    start: $baselineStart
    end: $baselineEnd
    storeIds: $storeIds
  )
  historicalData: kpiData(
    kpi: $kpi
    aggregation: $aggregation
    start: $start
    end: $end
    storeIds: $storeIds
  ) {
    start
    end
    value
  }
}
    `;

/**
 * __useGetKpiForTrendCardQuery__
 *
 * To run a query within a React component, call `useGetKpiForTrendCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiForTrendCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiForTrendCardQuery({
 *   variables: {
 *      kpi: // value for 'kpi'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      aggregation: // value for 'aggregation'
 *      baselineStart: // value for 'baselineStart'
 *      baselineEnd: // value for 'baselineEnd'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useGetKpiForTrendCardQuery(baseOptions: Apollo.QueryHookOptions<GetKpiForTrendCardQuery, GetKpiForTrendCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiForTrendCardQuery, GetKpiForTrendCardQueryVariables>(GetKpiForTrendCardDocument, options);
      }
export function useGetKpiForTrendCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiForTrendCardQuery, GetKpiForTrendCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiForTrendCardQuery, GetKpiForTrendCardQueryVariables>(GetKpiForTrendCardDocument, options);
        }
export type GetKpiForTrendCardQueryHookResult = ReturnType<typeof useGetKpiForTrendCardQuery>;
export type GetKpiForTrendCardLazyQueryHookResult = ReturnType<typeof useGetKpiForTrendCardLazyQuery>;
export type GetKpiForTrendCardQueryResult = Apollo.QueryResult<GetKpiForTrendCardQuery, GetKpiForTrendCardQueryVariables>;
export const GetLocationIndexDocument = gql`
    query getLocationIndex($storeIds: [Int], $dateYmd: String) {
  storeLocationIndex(storeIds: $storeIds, dateYmd: $dateYmd) {
    storeId
    storeTitle
    locationIndex
    storeIndex
  }
}
    `;

/**
 * __useGetLocationIndexQuery__
 *
 * To run a query within a React component, call `useGetLocationIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationIndexQuery({
 *   variables: {
 *      storeIds: // value for 'storeIds'
 *      dateYmd: // value for 'dateYmd'
 *   },
 * });
 */
export function useGetLocationIndexQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationIndexQuery, GetLocationIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationIndexQuery, GetLocationIndexQueryVariables>(GetLocationIndexDocument, options);
      }
export function useGetLocationIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationIndexQuery, GetLocationIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationIndexQuery, GetLocationIndexQueryVariables>(GetLocationIndexDocument, options);
        }
export type GetLocationIndexQueryHookResult = ReturnType<typeof useGetLocationIndexQuery>;
export type GetLocationIndexLazyQueryHookResult = ReturnType<typeof useGetLocationIndexLazyQuery>;
export type GetLocationIndexQueryResult = Apollo.QueryResult<GetLocationIndexQuery, GetLocationIndexQueryVariables>;
export const GetOccupancyForStoresDocument = gql`
    query GetOccupancyForStores {
  occupancy {
    storeId
    storeTitle
    currentValue
    limit
  }
}
    `;

/**
 * __useGetOccupancyForStoresQuery__
 *
 * To run a query within a React component, call `useGetOccupancyForStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyForStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyForStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOccupancyForStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetOccupancyForStoresQuery, GetOccupancyForStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupancyForStoresQuery, GetOccupancyForStoresQueryVariables>(GetOccupancyForStoresDocument, options);
      }
export function useGetOccupancyForStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupancyForStoresQuery, GetOccupancyForStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupancyForStoresQuery, GetOccupancyForStoresQueryVariables>(GetOccupancyForStoresDocument, options);
        }
export type GetOccupancyForStoresQueryHookResult = ReturnType<typeof useGetOccupancyForStoresQuery>;
export type GetOccupancyForStoresLazyQueryHookResult = ReturnType<typeof useGetOccupancyForStoresLazyQuery>;
export type GetOccupancyForStoresQueryResult = Apollo.QueryResult<GetOccupancyForStoresQuery, GetOccupancyForStoresQueryVariables>;
export const GetKpiForPerformanceChartDocument = gql`
    query GetKpiForPerformanceChart($kpi: Kpi!, $start: String, $end: String, $aggregation: Aggregation!, $storeIds: [Int!]) {
  data: kpiDataPerStore(
    kpi: $kpi
    aggregation: $aggregation
    start: $start
    end: $end
    storeIds: $storeIds
  ) {
    storeId
    kpiData {
      start
      end
      value
    }
  }
}
    `;

/**
 * __useGetKpiForPerformanceChartQuery__
 *
 * To run a query within a React component, call `useGetKpiForPerformanceChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiForPerformanceChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiForPerformanceChartQuery({
 *   variables: {
 *      kpi: // value for 'kpi'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      aggregation: // value for 'aggregation'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useGetKpiForPerformanceChartQuery(baseOptions: Apollo.QueryHookOptions<GetKpiForPerformanceChartQuery, GetKpiForPerformanceChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiForPerformanceChartQuery, GetKpiForPerformanceChartQueryVariables>(GetKpiForPerformanceChartDocument, options);
      }
export function useGetKpiForPerformanceChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiForPerformanceChartQuery, GetKpiForPerformanceChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiForPerformanceChartQuery, GetKpiForPerformanceChartQueryVariables>(GetKpiForPerformanceChartDocument, options);
        }
export type GetKpiForPerformanceChartQueryHookResult = ReturnType<typeof useGetKpiForPerformanceChartQuery>;
export type GetKpiForPerformanceChartLazyQueryHookResult = ReturnType<typeof useGetKpiForPerformanceChartLazyQuery>;
export type GetKpiForPerformanceChartQueryResult = Apollo.QueryResult<GetKpiForPerformanceChartQuery, GetKpiForPerformanceChartQueryVariables>;
export const GetStoresByIdsDocument = gql`
    query GetStoresByIds($ids: [Int!]) {
  data: stores(ids: $ids) {
    id
    title
  }
}
    `;

/**
 * __useGetStoresByIdsQuery__
 *
 * To run a query within a React component, call `useGetStoresByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetStoresByIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresByIdsQuery, GetStoresByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresByIdsQuery, GetStoresByIdsQueryVariables>(GetStoresByIdsDocument, options);
      }
export function useGetStoresByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresByIdsQuery, GetStoresByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresByIdsQuery, GetStoresByIdsQueryVariables>(GetStoresByIdsDocument, options);
        }
export type GetStoresByIdsQueryHookResult = ReturnType<typeof useGetStoresByIdsQuery>;
export type GetStoresByIdsLazyQueryHookResult = ReturnType<typeof useGetStoresByIdsLazyQuery>;
export type GetStoresByIdsQueryResult = Apollo.QueryResult<GetStoresByIdsQuery, GetStoresByIdsQueryVariables>;
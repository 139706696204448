import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Badge, Box, Stack, Toolbar } from "@mui/material";
import { AppBarProfileButton } from "./Layout/AppBarProfileButton";
import { NavigationMenuButton } from "./Layout/NavigationMenuButton";
import { useTranslation } from "react-i18next";

interface Props {}

const DevelopmentIndicator: React.FunctionComponent = ({ children }) => {
  if (process.env.NODE_ENV === "production") {
    return <>{children}</>;
  }

  return (
    <Badge badgeContent="dev" color="error" overlap="circular">
      {children}
    </Badge>
  );
};

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const { t } = useTranslation();
  const navigationItems = [
    {
      path: "/",
      label: t("Dashboard"),
    },
    {
      path: "/dashboard",
      label: "Old Dashboard",
    },
    {
      path: "/fillLevelDashboard",
      label: "FillLevelDashboard",
    },
    {
      path: "/fillLevel",
      label: t("Fill Level"),
    },
    {
      path: "/administration",
      label: t("Administration"),
    },
  ];

  return (
    <Stack flexGrow={1}>
      <AppBar position="fixed">
        <Toolbar>
          <NavigationMenuButton menuItems={navigationItems} />
          <Box sx={{ flexGrow: 1 }} />
          <DevelopmentIndicator>
            <AppBarProfileButton />
          </DevelopmentIndicator>
        </Toolbar>
      </AppBar>
      <Offset />
      {children}
    </Stack>
  );
};

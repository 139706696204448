import React from "react";
import { Box } from "@mui/material";
import {
  FillLevelData,
  FillLevelScreen,
  LayoutOptions,
  LayoutState,
} from "../Dashboard/FillLevelScreen";
import { LayoutPreviewFrame } from "./LayoutPreviewFrame";
import { MetricType } from "../Dashboard/MetricItem";

interface Props {
  data: FillLevelData;
  metricTypes: MetricType[];
  state: LayoutState;
  options: LayoutOptions;
}

export const LayoutPreview: React.FunctionComponent<Props> = ({
  data,
  metricTypes,
  state,
  options,
}) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box
        sx={{
          display: "inline-block",
          width: "72%",
          aspectRatio: "16 / 10",
        }}
      >
        <LayoutPreviewFrame>
          <FillLevelScreen
            data={data}
            metricTypes={metricTypes}
            state={state}
            options={options}
            orientation={"landscape"}
            scaleContents={0.6}
          />
        </LayoutPreviewFrame>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "25.3%",
          aspectRatio: "9 / 16",
        }}
      >
        <LayoutPreviewFrame>
          <FillLevelScreen
            data={data}
            metricTypes={metricTypes}
            state={state}
            options={options}
            orientation={"portrait"}
            scaleContents={0.6}
          />
        </LayoutPreviewFrame>
      </Box>
    </Box>
  );
};

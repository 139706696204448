import { Chart, Plugin } from "chart.js/auto";

export const NoDataOverlay: Plugin = {
  id: "NoDataOverlay",
  afterDraw(chart: Chart) {
    if (chart.data.datasets.length === 0) {
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height;

      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("No data to display", width / 2, height / 2);
      ctx.restore();
    }
  },
};

import React from "react";
import { FillLevelScreenContent } from "./FillLevelScreenContent";
import { FillLevelScreenBrandWrapperLandscape } from "./FillLevelScreenBrandWrapperLandscape";
import { FillLevelScreenBrandWrapperPortrait } from "./FillLevelScreenBrandWrapperPortrait";
import { MetricType } from "./MetricItem";

export type LayoutState = "ok" | "limit_exceeded" | "offline";
export type LayoutVariant = "simple" | "brand";
export type Orientation = "landscape" | "portrait";

export interface LayoutStateOptions {
  icon: string;
  greetingText: string;
  greetingSecondaryText: string;
}

export interface LayoutOptions {
  variant: LayoutVariant;
  stateOptions: {
    [key in LayoutState]: LayoutStateOptions;
  };
  brand: {
    name: string;
    slogan: string;
    color: string;
  };
}

export interface FillLevelData {
  current: number;
  limit: number;
  waitingTimeSeconds: number;
}

interface Props {
  data: FillLevelData;
  metricTypes: MetricType[];
  state: LayoutState;
  options: LayoutOptions;
  orientation: Orientation;
  scaleContents?: number;
}

export const FillLevelScreen: React.FunctionComponent<Props> = ({
  data,
  metricTypes,
  state,
  options,
  orientation,
  scaleContents = 1,
}) => {
  if (options.variant === "simple") {
    return (
      <FillLevelScreenContent
        data={data}
        metricTypes={metricTypes}
        state={state}
        options={options}
        orientation={orientation}
        scaleContents={scaleContents}
      />
    );
  }

  if (orientation === "landscape") {
    return (
      <FillLevelScreenBrandWrapperLandscape
        scaleContents={scaleContents}
        options={options}
      >
        <FillLevelScreenContent
          data={data}
          metricTypes={metricTypes}
          state={state}
          options={options}
          orientation={orientation}
          scaleContents={scaleContents}
        />
      </FillLevelScreenBrandWrapperLandscape>
    );
  }

  return (
    <FillLevelScreenBrandWrapperPortrait
      scaleContents={scaleContents}
      options={options}
    >
      <FillLevelScreenContent
        data={data}
        metricTypes={metricTypes}
        state={state}
        options={options}
        orientation={orientation}
        scaleContents={scaleContents}
      />
    </FillLevelScreenBrandWrapperPortrait>
  );
};

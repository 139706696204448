import React from "react";
import People from "@mui/icons-material/People";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import { Kpi } from "../../../generated/graphql";

interface Props {
  kpi: Kpi;
}

export const KpiIcon: React.FunctionComponent<Props> = ({ kpi }) => {
  switch (kpi) {
    case Kpi.Customer:
      return <ShoppingBag />;
    case Kpi.Visitor:
      return <People />;
    case Kpi.ConversionRate:
      return <div>%</div>;
  }

  return <div />;
};

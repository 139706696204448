import { useConnectApiFetch } from "../../Hook/useConnectApiFetch";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";

export const useLogin = () => {
  const fetch = useConnectApiFetch();
  const queryClient = useQueryClient();
  const doLogin = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      const response = await fetch("check_login.json", {
        headers: {
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: new URLSearchParams({
          username,
          password,
        }),
        method: "POST",
        credentials: "include",
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error();
      }

      queryClient.invalidateQueries();
    },
    [fetch, queryClient]
  );

  const mutation = useMutation(doLogin);

  return {
    doLogin: mutation.mutate,
    isError: mutation.isError,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

import React from "react";
import { Form, Formik } from "formik";
import { ObjectSchema } from "yup";

interface Props<T extends object> {
  initialValues: T;
  onSubmit: (values: T) => void;
  validationSchema?: ObjectSchema<Record<keyof T, any>>;
}

export const ValidatedForm = <T extends object>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}: React.PropsWithChildren<Props<T>>) => {
  const handleSubmit = (values: T) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form noValidate={true}>{children}</Form>
    </Formik>
  );
};

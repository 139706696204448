import React, { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

interface Props {
  headline?: ReactNode;
}

export const EditorPanelContainer: React.FunctionComponent<Props> = ({
  headline,
  children,
}) => {
  return (
    <Accordion>
      {headline && <AccordionSummary>{headline}</AccordionSummary>}
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

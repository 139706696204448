import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { useCurrentUser } from "../Authentication/AuthenticationGuard";
import { useLogout } from "../Authentication/Hook/useLogout";
import { Trans } from "react-i18next";

interface Props {}

export const AppBarProfileButton: React.FunctionComponent<Props> = () => {
  const user = useCurrentUser();
  const { doLogout } = useLogout();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    doLogout();
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <Avatar sx={{ backgroundColor: "secondary.main" }}>
          {user.username.charAt(0).toUpperCase()}
        </Avatar>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={RouterLink} to={"/profile"}>
          <Trans>My Account</Trans>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Trans>Logout</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};

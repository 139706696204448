import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MetricIcon } from "./MetricIcon";

export type MetricType = "current" | "available" | "limit" | "occupancy";

export type FillLevelMetric = {
  type: MetricType;
  unit: string;
  value: number;
};

interface Props {
  metric: FillLevelMetric;
  color: string;
}

export const MetricItem: React.FunctionComponent<Props> = ({
  metric,
  color,
}) => {
  const { t } = useTranslation("fillLevel");
  let value = metric.value;

  if (isNaN(value)) {
    value = 0;
  }

  return (
    <Stack direction="row" sx={{ marginX: 1 }}>
      <MetricIcon type={metric.type} color={color} />
      <Stack sx={{ justifyContent: "center" }}>
        <Box>
          <Typography variant={"h5"}>
            {value}
            {metric.unit}
          </Typography>
        </Box>
        <Box>
          <Typography variant={"caption"}>
            {t("metric_label_" + metric.type)}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

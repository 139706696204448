import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { LayoutOptions } from "./FillLevelScreen";

interface Props {
  scaleContents?: number;
  options: LayoutOptions;
}

export const FillLevelScreenBrandWrapperLandscape: React.FunctionComponent<Props> =
  ({ scaleContents = 1, children, options: { brand } }) => {
    return (
      <Box sx={{ height: "100%", width: "100%", backgroundColor: brand.color }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={7}>
            {children}
          </Grid>
          <Grid
            item
            xs={5}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-evenly"}
          >
            <Stack>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <img
                  alt="cat"
                  src={"https://cataas.com/cat?width=300&height=300"}
                />
              </Box>
              <Typography variant={"h2"}>{brand.name}</Typography>
              <Typography variant={"h6"}>{brand.slogan}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

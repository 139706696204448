import React, { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Kpi } from "../../../generated/graphql";
import {
  ChartConfigurationBar,
  getAggregationByPeriod,
  PerformanceChartPeriod,
} from "./ChartConfigurationBar";
import { startOfDay, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { PerformanceChartCard } from "./PerformanceChartCard";

interface Props {
  endDate: Date;
}

function getStartForEndDateAndPeriod(
  end: Date,
  period: PerformanceChartPeriod
): Date {
  switch (period) {
    case PerformanceChartPeriod.Day:
      return startOfDay(end);
    case PerformanceChartPeriod.Week:
      return startOfWeek(end);
    case PerformanceChartPeriod.Month:
      return startOfMonth(end);
    case PerformanceChartPeriod.Year:
      return startOfYear(end);
    default:
      return end;
  }
}

export const PerformanceDisplay: React.FunctionComponent<Props> = ({
  endDate,
}) => {
  const [selectedKpi, setSelectedKpi] = useState<Kpi>(Kpi.Visitor);
  const [period, setPeriod] = useState<PerformanceChartPeriod>(
    PerformanceChartPeriod.Day
  );
  const aggregation = useMemo(() => getAggregationByPeriod(period), [period]);
  const [storeIds, setStoreIds] = useState<number[]>([]);
  const startDate = useMemo(
    () => getStartForEndDateAndPeriod(endDate, period),
    [period, endDate]
  );

  return (
    <Stack spacing={2} sx={{ margin: 1, marginLeft: 3, height: "75%" }}>
      <ChartConfigurationBar
        kpi={selectedKpi}
        period={period}
        setKpi={setSelectedKpi}
        setPeriod={setPeriod}
        setSelectedStoreIds={setStoreIds}
      />
      <PerformanceChartCard
        selectedKpi={selectedKpi}
        aggregation={aggregation}
        startDate={startDate}
        endDate={endDate}
        storeIds={storeIds}
      />
    </Stack>
  );
};

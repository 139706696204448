import React from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useCurrentUser } from "./Core/Authentication/AuthenticationGuard";
import { Layout } from "./Core/Layout";
import { I18nextProvider } from "react-i18next";
import { Dashboard } from "./Dashboard/Dashboard";
import i18n from "i18next";
import { UserProfile } from "./UserProfile/UserProfile";
import { FillLevelDashboard } from "./FillLevel/FillLevelDashboard";
import { DashboardNew } from "./Dashboard/DashboardNew";
import { FillLevel } from "./FillLevel/FillLevel";
import { Administration } from "./Administration/Administration";

interface Props {}

export const AuthenticatedApp: React.FunctionComponent<Props> = () => {
  const user = useCurrentUser();

  return (
    <I18nextProvider
      i18n={i18n.cloneInstance({ lng: user.locale.replace("_", "-") })}
    >
      <Router>
        <Routes>
          {/*
            Fill Level Dashboard should be full screen, that's why we render a separate view here.
            This should be cleaned up eventually, when we are more clear how this actually works.
          */}
          <Route path="/fillLevelDashboard" element={<FillLevelDashboard />} />
          <Route>
            <h3>hello</h3>
          </Route>

          <Route
            path="/"
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="profile" element={<UserProfile />} />
            <Route path="administration/*" element={<Administration />} />
            <Route path="fillLevel/*" element={<FillLevel />} />
            <Route path="" element={<DashboardNew />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

import { Store } from "../../../generated/graphql";
import { Typography } from "@mui/material";

interface StoreAddressProps {
  store: Store;
}

export const StoreAddress: React.FunctionComponent<StoreAddressProps> = ({
  store,
}) => {
  if (!store.address) {
    return null;
  }

  return (
    <Typography variant={"body2"}>
      {store.address.street1} {store.address.street2}, {store.address.zip}{" "}
      {store.address.city}, {store.address.country}
    </Typography>
  );
};

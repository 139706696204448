import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
// @ts-ignore
import { locizePlugin } from "locize";

export const availableNamespaces = [
  "translation",
  "loginForm",
  "dashboard",
  "kpi",
  "editProfile",
  "fillLevel",
];

export const initI18n = () => {
  i18n.use(LanguageDetector);
  i18n.use(Backend);
  i18n.use(locizePlugin);

  return i18n
    .init({
      fallbackLng: (code) => {
        if (!code) {
          return ["en-US"];
        }

        if (code.startsWith("de")) {
          return ["de-DE"];
        }

        if (code.startsWith("en")) {
          return ["en-US"];
        }

        return ["en-US"];
      },

      supportedLngs: ["en-US", "de-DE"],
      load: "currentOnly",
      // nsSeparator: false,
      // keySeparator: false,

      saveMissing: process.env.NODE_ENV !== "production",
      // debug: process.env.NODE_ENV !== "production",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      ns: availableNamespaces,

      react: {
        useSuspense: false,
        bindI18n: "languageChanged editorSaved",
      },
      initImmediate: false,
      missingKeyNoValueFallbackToKey: true,

      backend: {
        projectId: "3600981b-c014-4c59-a1b1-2b93a10f87d3\n",
        apiKey: "a5a10ac1-1dc5-4d14-9773-c0ec97b160f2\n",
        referenceLng: "en-US",
        allowedAddOrUpdateHosts: ["localhost", "ui.connect.crosscan.localhost"],
      },
    })
    .then((t) => {
      return { t, i18n };
    });
};

import React from "react";
import MaterialUiDatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import { parse } from "date-fns";
import { useDateFormat } from "../../Localization/Hook/useDateFormat";

interface Props {
  label?: string;
  ymdValue: string;
  onChange: (ymdValue: string) => void;
}

export const DatePicker: React.FunctionComponent<Props> = ({
  label,
  ymdValue,
  onChange,
}) => {
  const format = useDateFormat();
  const mask = format(new Date(), "P").replace(/[0-9]/g, "_");

  return (
    <MaterialUiDatePicker
      mask={mask}
      label={label}
      value={parse(ymdValue, "yyyy-MM-dd", new Date())}
      onChange={(newValue) => {
        if (!newValue || isNaN(newValue.getTime())) {
          return;
        }
        onChange(format(newValue, "yyyy-MM-dd"));
      }}
      renderInput={(params) => {
        return <TextField {...params} />;
      }}
    />
  );
};

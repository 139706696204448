import React, { useContext } from "react";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { LocalizationProvider as MaterialLocalizationProvider } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { de, enUS } from "date-fns/locale";
import { Locale } from "date-fns";
interface Props {}

const DateFnsLocaleContext = React.createContext<undefined | Locale>(undefined);

export const LocalizationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  let locale = enUS;
  if (i18n.language.startsWith("de")) {
    locale = de;
  }

  return (
    <DateFnsLocaleContext.Provider value={locale}>
      <MaterialLocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        {children}
      </MaterialLocalizationProvider>
    </DateFnsLocaleContext.Provider>
  );
};

export const useDateFnsLocale = (): Locale => {
  const locale = useContext(DateFnsLocaleContext);

  if (!locale) {
    throw new Error(
      "You are missing a locale for date-fns in your React context. Did you forget to wrap the application in a <LocalizationProvider />?"
    );
  }

  return locale;
};

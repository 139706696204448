import * as Apollo from "@apollo/client";
import {
  GetKpiForPerformanceChartQuery,
  GetKpiForPerformanceChartQueryHookResult,
  GetKpiForPerformanceChartQueryVariables,
  KpiValue,
  StoreGroupedKpiData,
  useGetKpiForPerformanceChartQuery,
} from "../../../generated/graphql";
import { adjustZeroValues } from "./adjustZeroValues";
import { compareAsc } from "date-fns";

type GetAdjustedKpiForPerformanceChartQueryResult = Omit<
  GetKpiForPerformanceChartQueryHookResult,
  "data"
> & {
  data: GetKpiForPerformanceChartQuery;
};

function getLabels(data: StoreGroupedKpiData[]): string[] {
  const labels = data
    .map((storeGroupedKpiData) =>
      storeGroupedKpiData.kpiData.map((kpiData) => kpiData.start)
    )
    .flat(1);
  const labelSet = new Set(labels);
  return Array.from(labelSet.values());
}

function fillUpLabels(data: StoreGroupedKpiData[], labels: string[]) {
  data.forEach((storeGroupedKpiData) =>
    fillUpKpiData(storeGroupedKpiData.kpiData, labels)
  );
}

function fillUpKpiData(kpiData: KpiValue[], labels: string[]) {
  const existingKpiLabels = kpiData.map((kpiData) => kpiData.start);
  labels.forEach((label) => {
    const inKpiData = existingKpiLabels.indexOf(label);
    if (inKpiData === -1) {
      kpiData.push({ start: label, value: 0, end: "" });
    }
  });
  return kpiData.sort((a, b) =>
    compareAsc(new Date(a.start), new Date(b.start))
  );
}

export const useGetAdjustedKpiForPerformanceChartQuery = (
  baseOptions: Apollo.QueryHookOptions<
    GetKpiForPerformanceChartQuery,
    GetKpiForPerformanceChartQueryVariables
  >
): GetAdjustedKpiForPerformanceChartQueryResult => {
  const { data, ...rest } = useGetKpiForPerformanceChartQuery(baseOptions);
  let newData: StoreGroupedKpiData[] = [];
  if (data && data.data) {
    newData = data.data.map((storeValue) =>
      storeValue
        ? { ...storeValue, kpiData: adjustZeroValues(storeValue.kpiData) }
        : storeValue
    );
    const labels = getLabels(newData);
    fillUpLabels(newData, labels);
  }
  return {
    ...rest,
    data: { data: newData },
  };
};

import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { gql } from "@apollo/client";
import {
  OccupancyData,
  useGetOccupancyForStoresQuery,
} from "../../../generated/graphql";
import { Info, SecurityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LabeledProgressBar } from "./LabeledProgressBar";

export const GET_OCCUPANCY_FOR_STORES = gql`
  query GetOccupancyForStores {
    occupancy {
      storeId
      storeTitle
      currentValue
      limit
    }
  }
`;

const getIconColor = (percentage: number): "disabled" | "success" | "error" => {
  if (isNaN(percentage)) {
    return "disabled";
  }

  return percentage < 80 ? "success" : "error";
};

const rankingCompareFn = (lhs: OccupancyData, rhs: OccupancyData): number => {
  const lhsPercentage = lhs.currentValue / lhs.limit;
  const rhsPercentage = rhs.currentValue / rhs.limit;

  if (isNaN(lhsPercentage) && isNaN(rhsPercentage)) {
    // sort by title only
  } else if (isNaN(lhsPercentage) || lhsPercentage < rhsPercentage) {
    return 1;
  } else if (isNaN(rhsPercentage) || lhsPercentage > rhsPercentage) {
    return -1;
  }

  if (lhs.storeTitle < rhs.storeTitle) {
    return -1;
  } else if (lhs.storeTitle > rhs.storeTitle) {
    return 1;
  }

  return 0;
};

interface OccupancyRankingRowProps {
  data: OccupancyData;
}

const OccupancyRankingRow: React.FunctionComponent<OccupancyRankingRowProps> =
  ({ data }) => {
    const percentage = Math.round((data.currentValue / data.limit) * 100);
    const iconColor = getIconColor(percentage);

    return (
      <>
        <Grid item xs={3} md={2}>
          {!isNaN(percentage) && (
            <Typography variant={"subtitle2"} textAlign={"right"}>
              {percentage}%
            </Typography>
          )}
        </Grid>
        <Grid item xs={3} md={7}>
          <LabeledProgressBar label={data.storeTitle} value={percentage} />
        </Grid>
        <Grid item xs={2} md={1}>
          <Typography variant={"body2"} textAlign={"center"}>
            <SecurityOutlined color={iconColor} />
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography
            variant={"body2"}
            textAlign={"right"}
            noWrap={true}
            title={`${data.currentValue} / ${data.limit}`}
          >
            {data.currentValue} / {data.limit}
          </Typography>
        </Grid>
      </>
    );
  };

interface Props {}

export const OccupancyRankingCard: React.FunctionComponent<Props> = () => {
  const theme = useTheme();
  const { t } = useTranslation("occupancy");
  const { data, loading } = useGetOccupancyForStoresQuery();

  const sortedData = [...(data?.occupancy || [])].sort(rankingCompareFn);

  return (
    <Card variant={"outlined"}>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <CardHeader
        title={
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{t("RANKING_CARD_HEADLINE")}</Typography>
            <IconButton sx={{ p: 0 }}>
              <Info color={"action"} />
            </IconButton>
          </Stack>
        }
        sx={{ borderBottom: `1px solid ${theme.palette.grey["300"]}`, mb: 1 }}
        titleTypographyProps={{ variant: "subtitle1" }}
      />
      <CardContent>
        <Grid container spacing={1}>
          {sortedData.map((d: OccupancyData) => (
            <OccupancyRankingRow key={d.storeId} data={d} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

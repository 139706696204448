import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  value: React.ReactNode;
  icon: React.ReactNode;
  deviationValue?: React.ReactNode;
  loading?: boolean;
};

export const DashboardValueCardV2: React.FunctionComponent<Props> = ({
  title,
  description,
  value,
  deviationValue,
  icon,
  loading,
}) => {
  return (
    <Card>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <CardContent>
        <CardHeader
          title={
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Typography>{title}</Typography>
              <Tooltip title={!!description ? description : ""}>
                <InfoIcon />
              </Tooltip>
            </Stack>
          }
          sx={{ minHeight: 100 }}
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4">{value}</Typography>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {icon}
            <Typography variant="h6">({deviationValue})</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

import * as React from "react";
import { Card, Divider, MenuItem, Select, Stack } from "@mui/material";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { Comparison, Period } from "../../../generated/graphql";

interface Props {
  onPeriodChange?: (period: Period) => void;
  onComparePeriodChange?: (period: Comparison) => void;
}

function getDateIntervalForPeriod(period: string) {
  switch (period) {
    case "daily":
      return { start: startOfDay(new Date()), end: endOfDay(new Date()) };
    case "weekly":
      return { start: startOfWeek(new Date()), end: endOfWeek(new Date()) };
    case "monthly":
      return { start: startOfMonth(new Date()), end: endOfMonth(new Date()) };
    case "yearly":
      return { start: startOfYear(new Date()), end: endOfYear(new Date()) };
    default:
      return { start: startOfDay(new Date()), end: endOfDay(new Date()) };
  }
}

function getCompareOptionsForPeriod(period: Period) {
  switch (period) {
    case Period.Daily:
      return [
        { label: "Previous Day", value: Comparison.Day },
        { label: "Previous Week", value: Comparison.Week },
        { label: "Previous Month", value: Comparison.Month },
        { label: "Previous Year", value: Comparison.Year },
      ];
    case Period.Weekly:
      return [
        { label: "Previous Week", value: Comparison.Week },
        { label: "Previous Month", value: Comparison.Month },
        { label: "Previous Year", value: Comparison.Year },
      ];
    case Period.Monthly:
      return [
        { label: "Previous Month", value: Comparison.Month },
        { label: "Previous Year", value: Comparison.Year },
      ];
    case Period.Yearly:
      return [{ label: "Previous Year", value: Comparison.Year }];
    default:
      return [
        { label: "Previous Day", value: Comparison.Day },
        { label: "Previous Week", value: Comparison.Week },
        { label: "Previous Month", value: Comparison.Month },
        { label: "Previous Year", value: Comparison.Year },
      ];
  }
}

export const PeriodSelectionBar: React.FunctionComponent<Props> = ({
  onPeriodChange,
  onComparePeriodChange,
}) => {
  const [period, setPeriod] = React.useState<Period>(Period.Daily);
  const [comparePeriod, setComparePeriod] = React.useState<Comparison>(
    Comparison.Day
  );

  const compareOptions = getCompareOptionsForPeriod(period);
  const dateInterval = getDateIntervalForPeriod(period);

  return (
    <Card variant={"outlined"} sx={{ padding: "1em" }}>
      <Stack
        direction={"row"}
        spacing={{ xs: 1, sm: 2 }}
        alignItems={"center"}
        sx={{ marginBottom: 0 }}
      >
        <label>Period:</label>
        <Select
          value={period}
          variant={"standard"}
          onChange={(newValue) => {
            if (
              newValue.target.value === Period.Daily ||
              newValue.target.value === Period.Weekly ||
              newValue.target.value === Period.Monthly ||
              newValue.target.value === Period.Yearly
            ) {
              if (onPeriodChange) {
                onPeriodChange(newValue.target.value);
              }
              if (onComparePeriodChange) {
                const compareOptions = getCompareOptionsForPeriod(
                  newValue.target.value
                );
                onComparePeriodChange(compareOptions[0].value);
                setComparePeriod(compareOptions[0].value);
              }
              setPeriod(newValue.target.value);
            }
          }}
        >
          <MenuItem value={Period.Daily}>Daily</MenuItem>
          <MenuItem value={Period.Weekly}>Weekly</MenuItem>
          <MenuItem value={Period.Monthly}>Monthly</MenuItem>
          <MenuItem value={Period.Yearly}>Yearly</MenuItem>
        </Select>
        <label>Compare with:</label>
        <Select
          value={comparePeriod}
          variant={"standard"}
          onChange={(newValue) => {
            if (
              newValue.target.value === Comparison.Day ||
              newValue.target.value === Comparison.Week ||
              newValue.target.value === Comparison.Month ||
              newValue.target.value === Comparison.Year
            ) {
              if (onComparePeriodChange) {
                onComparePeriodChange(newValue.target.value);
              }
              setComparePeriod(newValue.target.value);
            }
          }}
        >
          {compareOptions?.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label ?? option.value}
              </MenuItem>
            );
          })}
        </Select>
        <Divider orientation="vertical" flexItem />
        {format(dateInterval.start, "P")} - {format(dateInterval.end, "P")}
      </Stack>
    </Card>
  );
};

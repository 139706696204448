import { format, parse, eachDayOfInterval } from "date-fns";

export const toYmdString = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const fromYmdString = (ymdString: string) => {
  return parse(ymdString, "yyyy-MM-dd", new Date(0, 0, 0, 0, 0, 0, 0));
};

/**
 * Returns ymdStrings for all dates between the start and enddate.
 *
 * Example:
 * getRangeForYmdString("2021-08-27", "2021-08-29");
 * Will return:
 * ["2021-08-27", "2021-08-28", "2021-08-29"]
 */
export const getRangeForYmdString = (
  startYmdString: string,
  endYmdString: string
): string[] => {
  return eachDayOfInterval({
    start: fromYmdString(startYmdString),
    end: fromYmdString(endYmdString),
  }).map((date) => toYmdString(date));
};

import { Link, Route, Routes } from "react-router-dom";
import { FillLevelLayoutEditor } from "./FillLevelLayoutEditor";
import { FillLevelStoreList } from "./FillLevelStoreList";

export const FillLevel: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <>
            <Link to={`editor`}>Editor</Link>
            <FillLevelStoreList />
          </>
        }
      />
      <Route
        path="editor"
        element={
          <>
            <Link to="..">Back</Link>
            <FillLevelLayoutEditor />
          </>
        }
      />
    </Routes>
  );
};

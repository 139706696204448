import { useQuery } from "react-query";
import { useConnectApiFetch } from "../../Hook/useConnectApiFetch";

export interface CurrentUser {
  id: string;
  locale: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  job_title?: string;
  phone?: string;
  cellphone?: string;
}
interface NamedPermissions {
  any?: string[];
}

export const useAuthenticationInformation = (): {
  isLoading: boolean;
  user?: CurrentUser;
  namedPermissions?: NamedPermissions;
  isFetching: boolean;
  refetch: () => void;
} => {
  const {
    currentUserId,
    isLoading: currentUserIdIsLoading,
    isFetching: currentUserIdIsFetching,
  } = useCurrentUserId();
  const fetch = useConnectApiFetch();
  const { data, isLoading, isFetching, refetch } = useQuery(
    "currentUser",
    async () => {
      const response = await fetch(currentUserId + "/.json");
      return response.json();
    },
    {
      enabled: !!currentUserId,
      refetchInterval: 1000 * 3 * 60,
      refetchIntervalInBackground: true,
    }
  );

  return {
    user: data?.user,
    namedPermissions: data?.namedPermissions,
    isLoading: isLoading || currentUserIdIsLoading,
    isFetching: isFetching || currentUserIdIsFetching,
    refetch: () => refetch(),
  };
};

const useCurrentUserId: () => {
  currentUserId: null | string;
  isLoading: boolean;
  isFetching: boolean;
} = () => {
  const fetch = useConnectApiFetch();
  const { data, isLoading, isFetching } = useQuery(
    "currentUserId",
    async () => {
      return await fetch(".json").then((res) => {
        if (res.status !== 200) {
          return null;
        }

        // we rely on the backend redirecting the homepage to the users homepage
        // thus, response URL should look something like https://v3.connect.crosscan.com/1601/,
        // and we split out the userID from the pathname
        let pathname = new URL(res.url).pathname;
        return pathname
          .substr(0, pathname.length - 1) // remove trailing slash
          .split("/")
          .pop();
      });
    },
    { staleTime: 1000 * 5 * 60 }
  );

  return {
    currentUserId: typeof data === "string" ? data : null,
    isLoading,
    isFetching,
  };
};

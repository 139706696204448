import { useConnectApiFetch } from "../../Hook/useConnectApiFetch";
import { useMutation } from "react-query";
import { useCallback } from "react";

export const usePasswordReset = () => {
  const fetch = useConnectApiFetch();
  const doResetPassword = useCallback(
    async (username: string) => {
      return await fetch("request_password_reset.json", {
        headers: {
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: new URLSearchParams({
          username,
        }),
        method: "POST",
        credentials: "include",
      });
    },
    [fetch]
  );

  const mutation = useMutation(doResetPassword);

  return {
    doResetPassword: mutation.mutate,
    isError: mutation.isError,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

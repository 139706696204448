import React, { useState } from "react";
import { Autocomplete, Checkbox, Chip, Stack, TextField } from "@mui/material";
import { gql } from "@apollo/client";
import { Store } from "../../../generated/graphql";
import { useStores } from "../Hook/useStores";
import {
  AddCircleOutline,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";

interface Props {
  onChange?: (storeIds: number[]) => void;
}

export const GET_STORES = gql`
  query GetStores($title: String) {
    stores(title: $title) {
      id
      title
    }
  }
`;

const UnderlinedChip = styled(Chip)({
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
});

export const MultiStoreSelectCombobox: React.FunctionComponent<Props> = ({
  onChange,
}) => {
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState<Store[]>([]);
  const [storesSelected, setStoresSelected] = useState(false);
  const { data } = useStores({ search });
  const { t } = useTranslation("storeSelection");
  return (
    <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
      <Autocomplete
        sx={{ minWidth: 200 }}
        isOptionEqualToValue={(option: Store, value: Store) =>
          option.id === value.id
        }
        disableCloseOnSelect
        onInputChange={(e, newValue) => setSearch(newValue)}
        inputValue={search}
        onChange={(evt, newValue: Store[]) => {
          setStores(newValue);
          if (onChange) {
            onChange(newValue.map((store: Store) => store.id));
          }
          setStoresSelected(newValue.length > 0);
        }}
        value={stores}
        multiple
        options={data?.stores ?? []}
        getOptionLabel={(option: Store) => {
          return option.title;
        }}
        popupIcon={<AddCircleOutline />}
        renderInput={(params) => (
          <Stack direction={"row"} alignItems={"baseline"}>
            <TextField
              placeholder={storesSelected ? "" : t("placeholder")}
              variant={"standard"}
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: storesSelected,
              }}
            />
          </Stack>
        )}
        renderTags={(tags, getTagProps) =>
          tags.map((option, index) => (
            <UnderlinedChip
              variant={"outlined"}
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize={"small"} />}
              checkedIcon={<CheckBox fontSize={"small"} />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
      />
    </Stack>
  );
};

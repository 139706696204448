import { Store } from "../../../generated/graphql";
import React from "react";
import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Bathtub, Circle, OpenInNew } from "@mui/icons-material";
import { StoreAddress } from "./StoreAddress";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface LocationsListProps {
  stores: Store[];
}

export const LocationsList: React.FunctionComponent<LocationsListProps> = ({
  stores,
}) => {
  const { t } = useTranslation("locations");
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table cellSpacing={1}>
        <TableHead>
          <TableRow>
            <TableCell>{t("LIST_HEADER_ICON")}</TableCell>
            <TableCell>{t("LIST_HEADER_LOCATION")}</TableCell>
            <TableCell align={"center"}>
              {t("LIST_HEADER_DEVICE_COUNT")}
            </TableCell>
            <TableCell align={"center"}>
              {t("LIST_HEADER_DEVICE_STATUS")}
            </TableCell>
            <TableCell align={"right"}>{t("LIST_HEADER_DETAILS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stores.map((store) => (
            <TableRow key={store.id}>
              <TableCell>
                <Avatar variant={"rounded"}>
                  <Bathtub />
                </Avatar>
              </TableCell>
              <TableCell>
                <Typography variant={"body1"}>{store.title}</Typography>
                <StoreAddress store={store} />
              </TableCell>
              <TableCell align={"center"}>0</TableCell>
              <TableCell align={"center"}>
                <Circle color={"success"} />
              </TableCell>
              <TableCell align={"right"}>
                <IconButton
                  onClick={() => navigate(`../store-details/${store.id}`)}
                >
                  <OpenInNew />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

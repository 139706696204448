import { useDebounce } from "react-use";
import { useState } from "react";

/**
 * Debounces changes of a value.
 *
 * @example Only update a search every 300ms:
 * ```js
 *   const [search, setSearch] = useState("");
 *   const debouncedSearch = useDebouncedValue(search, 300);
 *   // debouncedSearch only changes every 300ms
 * ```
 */
export const useDebouncedValue = <T>(value: T, ms: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    ms,
    [value]
  );

  return debouncedValue;
};

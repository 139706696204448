import React from "react";
import { Box, Typography } from "@mui/material";
import {
  EditProfile,
  UserData,
} from "../../ui-component-library/Users/EditProfile";
import { useCurrentUser } from "../Core/Authentication/AuthenticationGuard";
import { useEditProfile } from "./Hook/useEditProfile";

interface Props {}

export const UserProfile: React.FunctionComponent<Props> = () => {
  const user = useCurrentUser();
  const locale = user.locale.replace("_", "-");
  const { saveProfileData, isLoading } = useEditProfile();

  const handleSubmit = (userData: UserData) => {
    saveProfileData({
      ...userData,
      user_id: user.id,
      locale: userData.locale.replace("-", "_"),
    });
  };

  return (
    <Box>
      <Typography variant="h2">Profile</Typography>
      <EditProfile
        initialUserData={{ ...user, locale }}
        isSaving={isLoading}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

import React from "react";
import { Card, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Aggregation, Kpi } from "../../../generated/graphql";
import { KpiName } from "../../Core/Kpi/KpiName";
import { useTranslation } from "react-i18next";
import { MultiStoreSelectCombobox } from "../../Core/StoreSelection/MultiStoreSelectCombobox";
import { styled } from "@mui/styles";

interface PeriodNameProps {
  period: PerformanceChartPeriod;
}

const PeriodName: React.FunctionComponent<PeriodNameProps> = ({ period }) => {
  const { t } = useTranslation("performance");

  return t(`${period}`);
};

const TypographyNotWrapped = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "visible",
});

interface Props {
  kpi: Kpi;
  period: PerformanceChartPeriod;
  setKpi: (value: Kpi) => void;
  setPeriod: (value: PerformanceChartPeriod) => void;
  setSelectedStoreIds: (value: number[]) => void;
}

export enum PerformanceChartPeriod {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export function getAggregationByPeriod(
  period: PerformanceChartPeriod
): Aggregation {
  switch (period) {
    case PerformanceChartPeriod.Day:
      return Aggregation.Hourly;
    case PerformanceChartPeriod.Year:
      return Aggregation.Monthly;
    default:
      return Aggregation.Daily;
  }
}

export const ChartConfigurationBar: React.FunctionComponent<Props> = ({
  kpi,
  period,
  setKpi,
  setPeriod,
  setSelectedStoreIds,
}) => {
  const { t } = useTranslation("chartConfig");
  return (
    <Card variant={"outlined"} sx={{ padding: "1em" }}>
      <Stack direction="row" spacing={2} alignItems={"baseline"}>
        <TypographyNotWrapped noWrap>{t("display")}</TypographyNotWrapped>
        <Select
          value={kpi}
          variant={"standard"}
          onChange={(e) => setKpi(e.target.value as Kpi)}
        >
          {Object.values(Kpi).map((value) => (
            <MenuItem key={value} value={value}>
              <KpiName kpi={value} />
            </MenuItem>
          ))}
        </Select>
        <TypographyNotWrapped>{t("for")}</TypographyNotWrapped>
        <Select
          value={period}
          variant={"standard"}
          onChange={(e) => setPeriod(e.target.value as PerformanceChartPeriod)}
        >
          {Object.values(PerformanceChartPeriod).map((value) => (
            <MenuItem key={value} value={value}>
              <PeriodName period={value} />
            </MenuItem>
          ))}
        </Select>
        <TypographyNotWrapped>{t("with")}</TypographyNotWrapped>
        <MultiStoreSelectCombobox onChange={setSelectedStoreIds} />
      </Stack>
    </Card>
  );
};

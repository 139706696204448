import React from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#6AB8FF",
      main: "#0E89F8",
      dark: "#005DC4",
      contrastText: "#fff",
    },
    secondary: {
      light: "#B145C8",
      main: "#7E0497",
      dark: "#4D0068",
      contrastText: "#fff",
    },

    background: {
      default: "#f7f7f7",
    },
  },
});

interface Props {}

export const ThemeProvider: React.FunctionComponent<Props> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

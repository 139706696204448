import * as React from "react";
import { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../Misc/Copyright";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { object, string } from "yup";
import { ValidatedForm } from "../Form/ValidatedForm";
import { ValidatedTextField } from "../Form/ValidatedTextField";

interface Props {
  onSubmit: (username: string) => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

interface FormValues {
  username: string;
}

const resetSchema = object().shape({
  username: string().email("Invalid email").required("Required"),
});

export const PasswordResetForm: React.FunctionComponent<Props> = ({
  onSubmit,
  onCancel,
  isLoading = false,
}) => {
  const { t } = useTranslation("loginForm");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const initialValues = { username: "" };

  const handleSubmit = (values: FormValues) => {
    setSubmitted(true);
    onSubmit(values.username);
  };

  const handleCancel = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", alignSelf: "center" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ alignSelf: "center" }}>
          {t("headline")}
        </Typography>
        <ValidatedForm
          initialValues={initialValues}
          validationSchema={resetSchema}
          onSubmit={handleSubmit}
        >
          <ValidatedTextField
            label={t("username")}
            name={"username"}
            autoFocus={true}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!!isLoading}
          >
            {t("reset_password_button")}
          </Button>
          <Box visibility={isLoading ? "visible" : "hidden"}>
            <LinearProgress />
          </Box>
          <Box
            display={submitted && !isLoading ? "inline-flex" : "none"}
            sx={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Alert severity="info">{t("reset_confirmation")}</Alert>
          </Box>
          {onCancel ? (
            <Button onClick={handleCancel}>{t("back_to_login")}</Button>
          ) : null}
        </ValidatedForm>
      </Box>
      <Copyright />
    </Container>
  );
};

import React from "react";
import { Stack, Typography } from "@mui/material";
import { TrendValue } from "../../../ui-component-library/DataVisualization/TrendValue";
import { Kpi } from "../../../generated/graphql";

interface Props {
  kpi: Kpi;
  value: any;
  baselineValue: any;
}

export const KpiTrend: React.FunctionComponent<Props> = ({
  kpi,
  value,
  baselineValue,
}) => {
  if (isNaN(baselineValue) || baselineValue === 0) {
    return null;
  }

  return (
    <Stack direction="column" alignItems="center">
      <TrendValue value={value} baselineValue={baselineValue} />
      <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
        vs. last week
      </Typography>
    </Stack>
  );
};

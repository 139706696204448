import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { gql } from "@apollo/client";
import { useGetLocationIndexQuery } from "../../../generated/graphql";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HouseIcon from "@mui/icons-material/House";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChartDataLabels from "chartjs-plugin-datalabels";

interface Props {
  storeIds: number[];
  startDate: string;
  endDate: string;
}

export const GET_LOCATION_INDEX = gql`
  query getLocationIndex($storeIds: [Int], $dateYmd: String) {
    storeLocationIndex(storeIds: $storeIds, dateYmd: $dateYmd) {
      storeId
      storeTitle
      locationIndex
      storeIndex
    }
  }
`;

export const LocationIndexCard: React.FunctionComponent<Props> = ({
  storeIds,
  startDate,
  endDate,
}) => {
  const { data, loading } = useGetLocationIndexQuery({
    variables: {
      storeIds:
        storeIds.length === 0
          ? null
          : storeIds.map((storeId) => Number(storeId)),
      dateYmd: startDate,
    },
  });

  if (loading) {
    return (
      <Card>
        <Box visibility={loading ? "visible" : "hidden"}>
          <LinearProgress />
        </Box>
      </Card>
    );
  }

  let storeLocationIndexData = data?.storeLocationIndex?.slice() ?? [];

  storeLocationIndexData.sort(function (a, b) {
    const locationIndexA = a?.locationIndex ?? 0;
    const storeIndexA = a?.storeIndex ?? 0;
    const indexA = storeIndexA - locationIndexA;

    const locationIndexB = b?.locationIndex ?? 0;
    const storeIndexB = b?.storeIndex ?? 0;
    const indexB = storeIndexB - locationIndexB;

    return indexB - indexA;
  });

  if (storeLocationIndexData.length > 10) {
    const flopFive = storeLocationIndexData.slice(
      storeLocationIndexData.length - 5,
      storeLocationIndexData.length
    );
    const topFive = storeLocationIndexData.slice(0, 5);

    storeLocationIndexData = topFive.concat(flopFive);
  }

  let index = 0;

  let datasets: any[] = [];
  storeLocationIndexData.forEach(function (storeLocationIndex) {
    const locationIndex = storeLocationIndex?.locationIndex ?? 0;
    const storeIndex = storeLocationIndex?.storeIndex ?? 0;
    index = storeIndex - locationIndex;

    let backgroundColor = "rgba(255, 99, 132, 0.2)";
    let borderColor = "rgb(255, 99, 132)";
    if (index > 0) {
      backgroundColor = "rgba(117, 100, 255, 0.2)";
      borderColor = "rgb(117, 100, 255)";
    }

    datasets.push({
      maxBarThickness: 20,
      axis: "y",
      label: storeLocationIndex?.storeTitle,
      data: [index],
      fill: false,
      borderWidth: 1,
      backgroundColor: [backgroundColor],
      borderColor: [borderColor],
    });
  });

  const chartJsData = {
    labels: ["Location-Index"],
    datasets: datasets,
  };

  const options = {
    indexAxis: "y",
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line",
            xMin: 0,
            xMax: 0,
            borderColor: "rgb(0, 0, 0, 0.5)",
            borderWidth: 2,
          },
        },
      },
      datalabels: {
        display: true,
        anchor: function (context: any) {
          let value = context.dataset.data[context.dataIndex];
          return value > 0 ? "end" : "start";
        },
        align: function (context: any) {
          let value = context.dataset.data[context.dataIndex];
          return value > 0 ? "end" : "start";
        },
        offset: 5,
        formatter: function (value: any, context: any) {
          return value.toFixed(2) + "%";
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
    scales: {
      x: {
        min: -100,
        max: 100,
        maxBarThickness: 0.4,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        min: -100,
        max: 100,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };
  const icon = <LocationOnIcon />;
  return (
    <Card>
      <CardContent>
        <CardHeader
          avatar={icon}
          title="Location Index"
          subheader="How good is my local performance? Compared to my surrounding stores"
          sx={{ minHeight: 100 }}
        />
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4}>
            <Typography align="center">
              <HolidayVillageIcon fontSize="large" />
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography align="center">
              <HouseIcon fontSize="large" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Bar
              data={chartJsData}
              options={options}
              plugins={[ChartDataLabels]}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { Kpi } from "../../../generated/graphql";
import { gql } from "@apollo/client";
import React from "react";
import { KpiName } from "../../Core/Kpi/KpiName";
import { KpiIcon } from "../../Core/Kpi/KpiIcon";
import { KpiDescription } from "../../Core/Kpi/KpiDescription";
import { fromYmdString, toYmdString } from "../../Core/DateTime/ymdFunctions";
import { subDays } from "date-fns";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  LinearProgress,
} from "@mui/material";
import { HeatmapChart } from "./HeatmapChart";
import { useGetFilteredKpiForHeatmapQuery } from "../Hook/useGetFilteredKpiForHeatmap";
import { useTranslation } from "react-i18next";

interface Props {
  storeIds: number[];
  kpi: Kpi;
  date: string;
}

export const GET_KPI = gql`
  query GetKpiForHeatmap(
    $kpi: Kpi!
    $start: String
    $end: String
    $storeIds: [Int!]
  ) {
    kpiData(
      kpi: $kpi
      aggregation: HOURLY
      start: $start
      end: $end
      storeIds: $storeIds
    ) {
      start
      end
      value
    }
  }
`;

export const HeatmapCard: React.FunctionComponent<Props> = ({
  kpi,
  date,
  storeIds,
}) => {
  const { t } = useTranslation("kpi");
  const startDate: string = toYmdString(subDays(fromYmdString(date), 6));
  const { data, loading } = useGetFilteredKpiForHeatmapQuery({
    variables: {
      kpi: kpi,
      start: startDate + "T00:00:00",
      end: date + "T23:59:59",
      storeIds: storeIds,
    },
  });

  const hasData = data.kpiData.reduce(
    (hasData, v) => hasData || v.value > 0,
    false
  );

  return (
    <Card>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <CardContent>
        <CardHeader
          avatar={<KpiIcon kpi={kpi} />}
          title={<KpiName kpi={kpi} />}
          subheader={<KpiDescription kpi={kpi} />}
          sx={{ minHeight: 100 }}
        />
        <CardMedia sx={{ paddingX: 2 }}>
          {hasData ? (
            <HeatmapChart kpiValues={data.kpiData} />
          ) : (
            <Alert severity="warning">{t("insufficient_data")}</Alert>
          )}
        </CardMedia>
      </CardContent>
    </Card>
  );
};

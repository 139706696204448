import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Stack } from "@mui/material";
import { LanguagePicker } from "../Localization/LanguagePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { ValidatedForm } from "../Form/ValidatedForm";
import * as yup from "yup";
import { ValidatedTextField } from "../Form/ValidatedTextField";

export type UserData = {
  firstname: string;
  lastname: string;
  email: string;
  job_title?: string;
  phone?: string;
  cellphone?: string;
  locale: string;
};

interface Props {
  onSubmit: (newUserData: UserData) => void;
  isSaving?: boolean;
  initialUserData: UserData;
}

const validationSchema = yup.object().shape({
  firstname: yup.string().required("Required"),
  lastname: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  locale: yup.string(),
  job_title: yup.string(),
  phone: yup.string(),
  cellphone: yup.string(),
});

export const EditProfile: React.FunctionComponent<Props> = ({
  onSubmit,
  isSaving = false,
  initialUserData,
}) => {
  const { t } = useTranslation("editProfile");
  const [locale, setLocale] = useState(initialUserData.locale);

  const handleSubmit = (values: UserData) => {
    if (!onSubmit) {
      return;
    }

    onSubmit({
      ...values,
      locale,
    });
  };

  return (
    <Container component="main" maxWidth="md">
      <ValidatedForm
        initialValues={initialUserData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Box style={{ flex: 1 }}>
              <ValidatedTextField
                required={true}
                disabled={isSaving}
                label={t("firstname")}
                name={"firstname"}
              />
              <ValidatedTextField
                required={true}
                disabled={isSaving}
                label={t("lastname")}
                name={"lastname"}
              />
              <ValidatedTextField
                required={true}
                disabled={isSaving}
                label={t("email")}
                name={"email"}
              />
              <ValidatedTextField
                disabled={isSaving}
                label={t("job_title")}
                name={"job_title"}
              />
            </Box>
            <Box style={{ flex: 1 }} mt={0}>
              <ValidatedTextField
                disabled={isSaving}
                label={t("phone")}
                name={"phone"}
              />
              <ValidatedTextField
                disabled={isSaving}
                label={t("cellphone")}
                name={"cellphone"}
              />
              <Box sx={{ pt: 2 }}>
                <LanguagePicker
                  isDisabled={isSaving}
                  language={locale}
                  onChangeLanguage={(language) => setLocale(language)}
                />
              </Box>
            </Box>
          </Stack>
          <Box>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={!!isSaving}
              startIcon={<SaveIcon />}
            >
              {t("apply_button")}
            </LoadingButton>
          </Box>
        </Box>
      </ValidatedForm>
    </Container>
  );
};

import React from "react";
import { Stack, SvgIconProps } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

interface Props {
  value: number;
  baselineValue: number;
}

const percentageFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const getColor = (trendPercentage: number): SvgIconProps["color"] => {
  if (trendPercentage > 0) {
    return "success";
  }

  if (trendPercentage < 0) {
    return "error";
  }

  return "inherit";
};

export const TrendValue: React.FunctionComponent<Props> = ({
  value,
  baselineValue,
}) => {
  if (!baselineValue) {
    return null;
  }

  const trendPercentage = ((value - baselineValue) / baselineValue) * 100;
  const trendPercentageFormatted =
    percentageFormatter.format(Math.abs(trendPercentage)) + "%";
  const color = getColor(trendPercentage);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        color: `${color}.main`,
      }}
    >
      {trendPercentage < 0 ? (
        <ArrowCircleDownIcon
          fontSize="large"
          color="error"
          sx={{ transform: "rotate(-45deg)" }}
        />
      ) : null}
      {trendPercentage > 0 ? (
        <ArrowCircleUpIcon
          fontSize="large"
          color="success"
          sx={{ transform: "rotate(45deg)" }}
        />
      ) : null}
      {trendPercentage === 0 ? (
        <ArrowCircleUpIcon
          fontSize="large"
          color="inherit"
          sx={{ transform: "rotate(90deg)" }}
        />
      ) : null}
      {trendPercentageFormatted}
    </Stack>
  );
};

import React from "react";
import { useStores } from "../Core/Hook/useStores";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

interface Props {}

export const FillLevelStoreList: React.FunctionComponent<Props> = () => {
  const { data } = useStores({});
  return (
    <TableContainer component={Card}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Standort</TableCell>
            <TableCell align="right">Kapazität</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.stores.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">…………</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { useParams } from "react-router-dom";
import React from "react";
import { Typography } from "@mui/material";

export const StoreDetails: React.FunctionComponent = () => {
  const params = useParams();

  return (
    <Typography variant={"h4"}>Store-Details: {params.storeId}</Typography>
  );
};

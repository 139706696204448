import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  language: string;
  onChangeLanguage: (language: string) => void;
  isDisabled?: boolean;
}

export const LanguagePicker: React.FunctionComponent<Props> = ({
  language,
  onChangeLanguage,
  isDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel>{t("language")}</InputLabel>
      <Select
        disabled={!!isDisabled}
        value={language}
        label={t("language")}
        onChange={(evt) => onChangeLanguage(evt.target.value)}
      >
        <MenuItem value="de-DE">🇩🇪 Deutsch</MenuItem>
        <MenuItem value="en-US">🇺🇸 English</MenuItem>
      </Select>
    </FormControl>
  );
};

export const LanguagePickerI18Next: React.FunctionComponent = () => {
  const { i18n } = useTranslation();

  let language = i18n.language;
  const availableLanguages = ["de-DE", "en-US"];

  if (language && language.startsWith("de")) {
    language = "de-DE";
  }

  if (language && language.startsWith("en")) {
    language = "en-US";
  }

  if (!availableLanguages.includes(language)) {
    language = "en-US";
  }

  return (
    <LanguagePicker
      language={language}
      onChangeLanguage={(language) => i18n.changeLanguage(language)}
    />
  );
};

import * as React from "react";
import { useState } from "react";
import { LoginForm } from "./LoginForm";
import { PasswordResetForm } from "./PasswordResetForm";
import { Box } from "@mui/material";

interface Props {
  onPasswordReset: (username: string) => void;
  onLogin: (username: string, password: string) => void;
  isLoading?: boolean;
  credentialsInvalid?: boolean;
}

export const LoginScreen: React.FunctionComponent<Props> = ({
  onPasswordReset,
  onLogin,
  isLoading,
  credentialsInvalid,
}) => {
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
  if (showPasswordForm) {
    return (
      <PasswordResetForm
        onSubmit={onPasswordReset}
        onCancel={() => setShowPasswordForm(false)}
      />
    );
  }
  return (
    <Box sx={{ height: "100vh", paddingTop: 8 }}>
      <LoginForm
        onPasswordReset={() => setShowPasswordForm(true)}
        onSubmit={onLogin}
        isLoading={isLoading}
        credentialsInvalid={credentialsInvalid}
      />
    </Box>
  );
};

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  value: React.ReactNode;
  secondaryValue?: React.ReactNode;
  icon: React.ReactNode;
  bottomMedia?: React.ReactNode;
  loading?: boolean;
};

export const DashboardValueCard: React.FunctionComponent<Props> = ({
  title,
  description,
  value,
  secondaryValue,
  icon,
  bottomMedia,
  loading,
}) => {
  return (
    <Card>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <CardContent>
        <CardHeader
          avatar={icon}
          title={title}
          subheader={description}
          sx={{ minHeight: 100 }}
        />
        <Stack justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{value}</Typography>
          <Typography variant="h5" sx={{ minHeight: 55 }}>
            {secondaryValue}
          </Typography>
        </Stack>
      </CardContent>
      {bottomMedia ? <CardMedia>{bottomMedia}</CardMedia> : null}
    </Card>
  );
};

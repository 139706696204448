import { gql } from "@apollo/client";
import { useGetStoresQuery } from "../../../generated/graphql";
import { useDebouncedValue } from "../../Helpers/Hook/useDebouncedValue";

export const GET_STORES = gql`
  query GetStores($title: String) {
    stores(title: $title) {
      id
      title
    }
  }
`;

/**
 * Queries the users stores with an optional search string. Automatically debounces.
 * @param search
 * @param searchDebounce
 */
export const useStores = ({
  search = "",
  searchDebounce = 300,
}: {
  search?: string;
  searchDebounce?: number;
}) => {
  const debouncedSearch = useDebouncedValue(search, 300);
  const { data, loading, error } = useGetStoresQuery({
    variables: {
      title: debouncedSearch,
    },
  });

  return { data, loading, error };
};

import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LayoutOptions } from "./FillLevelScreen";

interface Props {
  scaleContents?: number;
  options: LayoutOptions;
}

export const FillLevelScreenBrandWrapperPortrait: React.FunctionComponent<Props> =
  ({ scaleContents = 1, children, options: { brand } }) => {
    return (
      <Box sx={{ height: "100%", width: "100%", backgroundColor: brand.color }}>
        <Box sx={{ height: "calc(100% - 150px)" }}>{children}</Box>
        <Box sx={{ height: "150px" }}>
          <Stack direction={"row"}>
            <Box sx={{ paddingX: "25px", paddingY: "35px" }}>
              <img
                alt="cat"
                src={"https://cataas.com/cat?width=80&height=80"}
              />
            </Box>
            <Stack display={"flex"} justifyContent={"center"}>
              <Typography variant={"h4"}>{brand.name}</Typography>
              <Typography variant={"caption"}>{brand.slogan}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  };

import * as React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../Misc/Copyright";
import { useTranslation } from "react-i18next";
import { LanguagePickerI18Next } from "../Localization/LanguagePicker";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { object, string } from "yup";
import { ValidatedForm } from "../Form/ValidatedForm";
import { ValidatedTextField } from "../Form/ValidatedTextField";

interface Props {
  onSubmit?: (username: string, password: string) => void;
  isLoading?: boolean;
  credentialsInvalid?: boolean;
  onPasswordReset?: () => void;
}

interface FormValues {
  username: string;
  password: string;
}

const signupSchema = object().shape({
  username: string().email("Invalid email").required("Required"),
  password: string().required("Required"),
});

export const LoginForm: React.FunctionComponent<Props> = ({
  onSubmit,
  onPasswordReset,
  isLoading = false,
  credentialsInvalid = false,
}) => {
  const { t } = useTranslation("loginForm");

  const handleSubmit = (values: FormValues) => {
    if (!onSubmit) {
      return;
    }

    onSubmit(values.username, values.password);
  };

  const handleClickPasswordReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (!onPasswordReset) {
      return;
    }

    onPasswordReset();
  };

  const initialValues: FormValues = { username: "", password: "" };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("headline")}
        </Typography>
        <ValidatedForm
          initialValues={initialValues}
          validationSchema={signupSchema}
          onSubmit={handleSubmit}
        >
          <ValidatedTextField
            label={t("username")}
            name="username"
            autoFocus={true}
          />
          <ValidatedTextField
            label={t("password")}
            name="password"
            type="password"
          />
          <Box
            display={credentialsInvalid ? "inline-flex" : "none"}
            sx={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Alert severity="error">{t("wrong_credentials")}</Alert>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!!isLoading}
          >
            {t("sign_in_button")}
          </Button>
          <Box visibility={isLoading ? "visible" : "hidden"}>
            <LinearProgress />
          </Box>
          {onPasswordReset ? (
            <Button onClick={handleClickPasswordReset}>
              {t("forgot_password")}
            </Button>
          ) : null}
        </ValidatedForm>
      </Box>
      <Box sx={{ pt: 8 }}>
        <LanguagePickerI18Next />
      </Box>
      <Copyright />
    </Container>
  );
};

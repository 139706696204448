import { gql } from "@apollo/client";
import { useGetAdministrationLocationStoresQuery } from "../../../generated/graphql";
import { useDebouncedValue } from "../../Helpers/Hook/useDebouncedValue";

export const GET_ADMINISTRATION_LOCATION_STORES = gql`
  query GetAdministrationLocationStores($title: String) {
    stores(title: $title) {
      id
      title
      address {
        street1
        street2
        zip
        city
        country
      }
      geoLocation {
        latitude
        longitude
      }
    }
  }
`;

interface Created {
  query?: string;
  debounceMs?: number;
}

/**
 * Queries the users' stores with an optional query string. Automatically debounces.
 *
 * @param query
 * @param searchDebounce
 */
export const useStores = ({ query = "", debounceMs = 300 }: Created) => {
  const debouncedSearch = useDebouncedValue(query, debounceMs);
  const { data, loading, error } = useGetAdministrationLocationStoresQuery({
    variables: {
      title: debouncedSearch,
    },
  });

  return { data, loading, error };
};

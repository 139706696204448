import { useBaseUrl } from "./useEnvironment";
import { useCallback } from "react";
import { useCurrentUser } from "../Authentication/AuthenticationGuard";

export const useConnectApiFetch: () => typeof fetch = () => {
  const baseUrl = useBaseUrl();

  // We need to disable the check because eslint cannot detect this correctly as the function is not defined inline
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(makeFetchWithBaseUrl(baseUrl, fetch), [baseUrl]);
};

type ConnectApiFetch<ResponseData> = (
  url: string,
  data?: Record<string, string>,
  abortSignal?: AbortSignal
) => Promise<{ response: Response; data: ResponseData }>;

export const useLegacyConnectApiFormPost: () => ConnectApiFetch<any> = () => {
  const fetch = useAuthenticatedLegacyConnectApiFetch();

  return async (url, requestData) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams(requestData),
    });

    const data = await response.json();

    return {
      response,
      data,
    };
  };
};

export const useLegacyConnectApiGet: <
  ResponseData
>() => ConnectApiFetch<ResponseData> = () => {
  const fetch = useAuthenticatedLegacyConnectApiFetch();

  return async (url, requestData, abortSignal?: AbortSignal) => {
    const response = await fetch(
      url + "?" + new URLSearchParams(requestData).toString(),
      { signal: abortSignal }
    );

    const data = await response.json();

    return { data, response };
  };
};

export const useAuthenticatedLegacyConnectApiFetch: () => typeof fetch = () => {
  const user = useCurrentUser();
  const fetch = useConnectApiFetch();

  // We need to disable the check because eslint cannot detect this correctly as the function is not defined inline
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(makeFetchWithBaseUrl(`${user.id}/`, fetch), [
    fetch,
    user.id,
  ]);
};

/**
 * Helper to create a new fetch function with a baseUrl prefix
 */
const makeFetchWithBaseUrl = (
  baseUrl: string,
  innerFetch: typeof fetch
): typeof fetch => {
  return (originalInput, init) => {
    let input: RequestInfo;
    if (typeof originalInput === "string") {
      input = `${baseUrl}${originalInput}`;
    } else {
      input = {
        ...originalInput,
        url: `${baseUrl}$(originalInput.url}`,
      };
    }

    return innerFetch(input, init);
  };
};

import React from "react";
import { useFormikContext } from "formik";
import { TextField } from "@mui/material";

interface Props {
  label: string;
  name: string;
  type?: "password" | "text";
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const ValidatedTextField = ({
  name,
  label,
  type = "text",
  autoFocus = false,
  required = false,
  disabled = false,
}: React.PropsWithChildren<Props>) => {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<Record<string, unknown>>();

  return (
    <TextField
      margin="normal"
      fullWidth
      required={required}
      disabled={disabled}
      id={name}
      label={label}
      name={name}
      type={type}
      autoComplete={name}
      autoFocus={autoFocus}
      value={values[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched[name] && Boolean(errors[name])}
      helperText={touched[name] && errors[name]}
    />
  );
};

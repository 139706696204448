import React from "react";
import { Box } from "@mui/material";

interface Props {}

export const LayoutPreviewFrame: React.FunctionComponent<Props> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        border: 5,
        height: "calc(100% - 5px)",
        width: "calc(100% -5px)",
        overflow: "hidden",
      }}
    >
      {children}
    </Box>
  );
};

import React from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IndicatorIconSelection } from "./IndicatorIconSelection";
import { LayoutState } from "../Dashboard/FillLevelScreen";
import { EditorPanelContainer } from "./EditorPanelContainer";
import { TextField } from "../../../ui-component-library/Form/Field/TextField";
import { Field } from "formik";

interface Props {
  state: LayoutState;
}

export const LayoutOptionsPanel: React.FunctionComponent<Props> = ({
  state,
}) => {
  const { t } = useTranslation("fillLevel");

  return (
    <EditorPanelContainer headline={t(`stateOptions.${state}.headline`)}>
      <Stack direction={"row"} spacing={1}>
        <Box flex={1}>
          <Field
            as={IndicatorIconSelection}
            name={`stateOptions.${state}.icon`}
            state={state}
          />
        </Box>
        <Box flex={3}>
          <Field
            as={TextField}
            name={`stateOptions.${state}.greetingText`}
            label={t(`stateOptions.${state}.greetingText.label`)}
          />
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Box flex={1}>
          <Field
            as={TextField}
            name={`stateOptions.${state}.greetingSecondaryText`}
            label={t(`stateOptions.${state}.greetingSecondaryText.label`)}
          />
        </Box>
      </Stack>
    </EditorPanelContainer>
  );
};

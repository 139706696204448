import React from "react";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Aggregation,
  Kpi,
  Store,
  StoreGroupedKpiData,
  useGetStoresByIdsQuery,
} from "../../../generated/graphql";
import { gql } from "@apollo/client";
import { useGetAdjustedKpiForPerformanceChartQuery } from "../Hook/useGetAdjustedKpiForPerformanceChart";
import { KpiLineChart } from "./KpiLineChart";
import { useAggregatedChartData } from "../Hook/useAggregatedChartData";
import { Info } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

type Props = {
  selectedKpi: Kpi;
  aggregation: Aggregation;
  startDate: Date;
  endDate: Date;
  storeIds: number[];
};

export const GET_KPI = gql`
  query GetKpiForPerformanceChart(
    $kpi: Kpi!
    $start: String
    $end: String
    $aggregation: Aggregation!
    $storeIds: [Int!]
  ) {
    data: kpiDataPerStore(
      kpi: $kpi
      aggregation: $aggregation
      start: $start
      end: $end
      storeIds: $storeIds
    ) {
      storeId
      kpiData {
        start
        end
        value
      }
    }
  }
`;

export const GET_STORES_BY_IDS = gql`
  query GetStoresByIds($ids: [Int!]) {
    data: stores(ids: $ids) {
      id
      title
    }
  }
`;

export const PerformanceChartCard: React.FunctionComponent<Props> = ({
  selectedKpi,
  aggregation,
  startDate,
  endDate,
  storeIds,
}) => {
  const { t } = useTranslation("performance");
  const theme = useTheme();
  const { data, loading } = useGetAdjustedKpiForPerformanceChartQuery({
    variables: {
      kpi: selectedKpi,
      start: format(startDate, "yyyy-MM-dd'T'hh:mm:ss"),
      end: format(endDate, "yyyy-MM-dd'T'hh:mm:ss"),
      aggregation: aggregation,
      storeIds: storeIds,
    },
  });
  let kpiData: StoreGroupedKpiData[] = data.data ?? [];
  const { data: stores, loading: loadingStores } = useGetStoresByIdsQuery({
    variables: {
      ids: kpiData.map((value: StoreGroupedKpiData) => value.storeId as number),
    },
  });
  const chartData = useAggregatedChartData({
    aggregation: aggregation,
    historicalValues: kpiData,
  });

  const getStoreTitle = (label: string): string => {
    const store = stores?.data?.find((store: Store) => store.id + "" === label);
    return store ? store.title : label;
  };

  chartData.datasets = chartData.datasets.map((value) => ({
    ...value,
    label: getStoreTitle(value.label ?? ""),
  }));

  return (
    <Card>
      <Box visibility={loading && loadingStores ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <CardHeader
        title={
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{t("PERFORMANCE_CARD_HEADLINE")}</Typography>
            <IconButton sx={{ p: 0 }}>
              <Info color={"action"} />
            </IconButton>
          </Stack>
        }
        sx={{ borderBottom: `1px solid ${theme.palette.grey["300"]}` }}
        titleTypographyProps={{ variant: "subtitle1" }}
      />
      <KpiLineChart data={chartData} />
    </Card>
  );
};

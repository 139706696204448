import {
  Aggregation,
  KpiValue,
  StoreGroupedKpiData,
} from "../../../generated/graphql";
import { useDateFormat } from "../../../ui-component-library/Localization/Hook/useDateFormat";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ChartData } from "chart.js/auto";

type useAggregatedChartDataVariables = {
  aggregation: Aggregation;
  historicalValues: StoreGroupedKpiData[];
};

export const useAggregatedChartData = ({
  aggregation,
  historicalValues,
}: useAggregatedChartDataVariables): ChartData => {
  const dateFormat = useDateFormat();
  const { t } = useTranslation("dateFormat");

  const getLabelForValue = useMemo(() => {
    var formatString = "";
    switch (aggregation) {
      case Aggregation.Hourly:
        formatString = "p";
        break;
      case Aggregation.Daily:
        formatString = "P";
        break;
      case Aggregation.Weekly:
        formatString = `'${t("week")}' w '${t("of")}' y`;
        break;
      case Aggregation.Monthly:
        formatString = "MMM y";
        break;
      case Aggregation.Yearly:
        formatString = "y";
        break;
    }
    return ({ start }: KpiValue) => {
      return dateFormat(Date.parse(start), formatString);
    };
  }, [t, dateFormat, aggregation]);

  return useMemo(
    () => ({
      labels:
        historicalValues.length > 0
          ? historicalValues[0].kpiData.map((kpiValue) =>
              getLabelForValue(kpiValue)
            )
          : [],
      datasets: historicalValues.map((storeData) => ({
        label: storeData.storeId ? storeData.storeId + "" : "All Stores",
        data: storeData.kpiData.map((kpiValue) => kpiValue.value),
      })),
    }),
    [getLabelForValue, historicalValues]
  );
};

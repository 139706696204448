import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { ChartData } from "chart.js/auto";
import { useTheme } from "@mui/material";

interface Props {
  data: ChartData;
}

export const DashboardValueCardChart: React.FunctionComponent<Props> = ({
  data,
}) => {
  const { palette } = useTheme();
  const chartData: ChartData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: palette.primary.main,
      borderWidth: 0,
    })),
  };
  const options: ChartOptions<"bar"> = {
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return <Bar data={chartData} options={options} height={60} />;
};

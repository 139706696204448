import { Navigate, Route, Routes } from "react-router-dom";
import { Locations } from "./Locations";
import React from "react";
import { Grid, Stack } from "@mui/material";
import { AdminMenu, AdminMenuEntry } from "./Component/AdminMenu";
import { LocationMenu } from "./Component/LocationMenu";

export const Administration: React.FunctionComponent = () => {
  return (
    <Grid
      container
      spacing={0}
      height={"100%"}
      maxHeight={"100%"}
      sx={{ display: "flex" }}
    >
      <Grid
        item
        xs={4}
        sx={{
          borderRight: "1px solid #000",
          display: "flex",
          height: "100%",
          flexGrow: 1,
          maxHeight: "100%",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route
            index
            element={<AdminMenu selected={AdminMenuEntry.LOCATIONS} />}
          />
          <Route
            path={"locations/*"}
            element={<AdminMenu selected={AdminMenuEntry.LOCATIONS} />}
          />
          <Route
            path={"devices/*"}
            element={<AdminMenu selected={AdminMenuEntry.DEVICES} />}
          />
          <Route
            path={"users/*"}
            element={<AdminMenu selected={AdminMenuEntry.USERS} />}
          />
        </Routes>

        <Stack height={"1px"} overflow={"auto"} flexGrow={1}>
          <Routes>
            <Route index element={<LocationMenu />} />
            <Route path={"locations/*"} element={<LocationMenu />} />
            <Route path={"devices/*"} element={null} />
            <Route path={"users/*"} element={null} />
          </Routes>
        </Stack>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          display: "flex",
          height: "100%",
          flexGrow: 1,
          maxHeight: "100%",
          flexDirection: "column",
          p: 4,
        }}
      >
        <Routes>
          <Route index element={<Navigate to={"locations"} />} />
          <Route path={"locations/*"} element={<Locations />} />
          <Route path={"devices/*"} element={<div>Devices</div>} />
          <Route path={"users/*"} element={<div>Users</div>} />
        </Routes>
      </Grid>
    </Grid>
  );
};

import React from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LayoutState } from "../Dashboard/FillLevelScreen";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoopIcon from "@mui/icons-material/Loop";

interface Props {
  state: LayoutState;
  onStateChanged: (state: LayoutState) => void;
}

export const LayoutStateSwitch: React.FunctionComponent<Props> = ({
  state,
  onStateChanged,
}) => {
  const handleStateChange = (newState: LayoutState | null) => {
    if (newState !== null) {
      onStateChanged(newState);
    }
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={state}
        exclusive
        onChange={(event, newState) => handleStateChange(newState)}
        aria-label="text alignment"
      >
        <ToggleButton value="ok">
          <CheckCircleOutlineIcon htmlColor={"green"} />
        </ToggleButton>
        <ToggleButton value="limit_exceeded">
          <ErrorOutlineIcon htmlColor={"red"} />
        </ToggleButton>
        <ToggleButton value="offline">
          <LoopIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

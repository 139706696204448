import { Button, LinearProgress, Stack, TextField } from "@mui/material";
import { LocationMenuList, LocationMenuNode } from "./LocationMenuList";
import {
  Add,
  ArrowDropDown,
  Business,
  LocationCity,
  Search,
  Store as StoreIcon,
} from "@mui/icons-material";
import {
  Store,
  StoreGroup,
  useGetAdministrationLocationStoreGroupsQuery,
} from "../../../generated/graphql";
import { useState } from "react";
import { useStores } from "../Hook/useStores";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";

export const GET_ADMINISTRATION_LOCATION_STORE_GROUPS = gql`
  fragment storeGroup on StoreGroup {
    id
    title
  }

  query GetAdministrationLocationStoreGroups {
    storeGroups {
      ...storeGroup
      children {
        ...storeGroup
        children {
          ...storeGroup
          children {
            ...storeGroup
            children {
              ...storeGroup
              children {
                ...storeGroup
                children {
                  ...storeGroup
                  children {
                    ...storeGroup
                    children {
                      ...storeGroup
                      children {
                        ...storeGroup
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const storeGroupsToTreeNodes = (
  storeGroups: StoreGroup[]
): LocationMenuNode[] =>
  storeGroups.map((group) => ({
    title: group.title,
    avatar:
      group.children && group.children.length > 0 ? (
        <ArrowDropDown color={"action"} />
      ) : (
        <LocationCity color={"action"} />
      ),
    settingsUrl: `./edit-store-group/${group.id}`,
    monitoringState: "success",
    children: storeGroupsToTreeNodes(group.children ?? []),
  }));

const storesToTreeNodes = (stores: Store[]): LocationMenuNode[] =>
  stores.map((store) => ({
    title: store.title,
    avatar: <StoreIcon color={"action"} />,
    url: `./store-details/${store.id}`,
    monitoringState: "success",
    children: [],
  }));

interface LocationMenuProps {}

export const LocationMenu: React.FunctionComponent<LocationMenuProps> = () => {
  const { t } = useTranslation("locations");
  const [query, setQuery] = useState("");
  const { data: stores, loading: loadingStores } = useStores({ query });
  const { data: storeGroups, loading: loadingStoreGroups } =
    useGetAdministrationLocationStoreGroupsQuery();

  const loading = loadingStores || loadingStoreGroups;

  const tree: LocationMenuNode = {
    title: "Crosscan GmbH",
    avatar: <Business color={"action"} />,
    settingsUrl: "./edit-company",
    children: [
      {
        title: t("ALL_LOCATIONS"),
        avatar: <ArrowDropDown color={"action"} />,
        url: "./all",
        settingsUrl: "./edit-all-locations",
        children: storesToTreeNodes(stores?.stores ?? []),
      },
      ...storeGroupsToTreeNodes(storeGroups?.storeGroups ?? []),
    ],
  };

  return (
    <Stack
      flexGrow={1}
      height={"1px"}
      padding={4}
      sx={{ backgroundColor: "grey.200" }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        sx={{
          display: "flex",
          flexGrow: 0,
          mb: 1,
        }}
      >
        <TextField
          variant={"outlined"}
          InputProps={{
            endAdornment: <Search />,
          }}
          fullWidth={true}
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          autoFocus={true}
        />
        <Button variant={"outlined"} color={"primary"}>
          <Add />
        </Button>
      </Stack>

      <Stack flexGrow={1} height={"1px"} overflow={"auto"}>
        {loading && <LinearProgress variant={"indeterminate"} />}

        {!loading && <LocationMenuList nodes={[tree]} />}
      </Stack>
    </Stack>
  );
};

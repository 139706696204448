import React from "react";
import { DashboardValueCardV2 } from "./DashboardValueCardV2";
import { KpiName } from "../../Core/Kpi/KpiName";
import { KpiDescription } from "../../Core/Kpi/KpiDescription";
import { KpiValue } from "../../Core/Kpi/KpiValue";
import { gql } from "@apollo/client";
import {
  Comparison,
  Kpi,
  Period,
  useGetKpiForCardV2Query,
} from "../../../generated/graphql";
import { format } from "date-fns";
import { Stack } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { red, green } from "@mui/material/colors";

interface Props {
  kpi: Kpi;
  period: Period;
  comparison: Comparison;
  date: number | Date;
}

export const GET_KPI_V2 = gql`
  query GetKpiForCardV2(
    $kpi: Kpi!
    $period: Period!
    $comparison: Comparison!
    $date: String
  ) {
    total: countingDataTotalByPeriod(kpi: $kpi, period: $period, date: $date)
    totalPrevious: countingDataTotalByPeriod(
      kpi: $kpi
      period: $period
      comparison: $comparison
      date: $date
    )
  }
`;

export const KpiCardV2: React.FunctionComponent<Props> = (props) => {
  const { kpi, period, comparison, date } = props;

  const { data, loading: isLoading } = useGetKpiForCardV2Query({
    variables: {
      kpi: kpi,
      period: period,
      comparison: comparison,
      date: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
    },
  });

  let currentValue = 0;
  let previousValue = 0;
  let deviationValue = 0;
  if (data !== undefined && data.total !== undefined) {
    currentValue = data.total;
  }
  if (data !== undefined && data.totalPrevious !== undefined) {
    previousValue = data.totalPrevious;
  }
  deviationValue = currentValue - previousValue;

  let icon = <SentimentSatisfiedIcon fontSize="large" />;
  if (deviationValue > 0) {
    icon = (
      <SentimentSatisfiedAltIcon fontSize="large" sx={{ color: green[500] }} />
    );
  } else if (deviationValue < 0) {
    icon = (
      <SentimentVeryDissatisfiedIcon
        fontSize="large"
        sx={{ color: red[500] }}
      />
    );
  }

  return (
    <Stack spacing={2} sx={{ margin: "1em" }}>
      <DashboardValueCardV2
        value={<KpiValue kpi={kpi} value={currentValue} />}
        deviationValue={<KpiValue kpi={kpi} value={deviationValue} />}
        icon={icon}
        title={<KpiName kpi={kpi} />}
        description={<KpiDescription kpi={kpi} />}
        loading={isLoading}
      />
    </Stack>
  );
};

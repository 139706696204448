import { LayoutState } from "./Dashboard/FillLevelScreen";
import formatDuration from "date-fns/formatDuration";

export const getBackgroundColor = (state: LayoutState) => {
  switch (state) {
    case "ok":
      return "#429335";
    case "offline":
      return "#3c8cb9";
    case "limit_exceeded":
      return "#ae2419";
  }
};

export const formatWaitingTime = (seconds: number) => {
  if (seconds <= 60) {
    return formatDuration(
      {
        seconds,
      },
      { format: ["seconds"] }
    );
  }

  return formatDuration(
    {
      minutes: Math.floor(seconds / 60),
      seconds: seconds % 60,
    },
    { format: ["minutes", "seconds"] }
  );
};

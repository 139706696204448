import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { MetricsList } from "./MetricsList";
import { MetricType } from "./MetricItem";
import { IndicatorIcon } from "../../Dashboard/IndicatorIcon";
import {
  FillLevelData,
  LayoutOptions,
  LayoutState,
  Orientation,
} from "./FillLevelScreen";
import { formatWaitingTime, getBackgroundColor } from "../utils";

interface Props {
  data: FillLevelData;
  metricTypes: MetricType[];
  state: LayoutState;
  options: LayoutOptions;
  orientation: Orientation;
  scaleContents?: number;
}

export const FillLevelScreenContent: React.FunctionComponent<Props> = ({
  data,
  metricTypes,
  state,
  options,
  orientation,
  scaleContents = 1,
}) => {
  const stateOptions = options.stateOptions[state];
  const waitingTime = formatWaitingTime(data.waitingTimeSeconds);
  const backgroundColor = getBackgroundColor(state);
  const primaryColor = "white";

  let iconBackgroundSize = 400;
  let iconSize = 240;
  let extraScaleFactor = 1;

  if (orientation === "portrait") {
    iconBackgroundSize = 200;
    iconSize = 140;
    extraScaleFactor = 0.5;
  }

  return (
    <Box
      sx={{
        backgroundColor,
        color: primaryColor,
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Box
            sx={{
              transform: `scale(${scaleContents})`,
              transformOrigin: "50% 0",
              margin: `30px 0 calc(-${iconBackgroundSize}px * ${
                1 - scaleContents
              })`,
              borderRadius: "100%",
              color: backgroundColor,
              backgroundColor: primaryColor,
              fontSize: iconSize,
              width: iconBackgroundSize,
              aspectRatio: "1 / 1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IndicatorIcon
              icon={options.stateOptions[state].icon}
              color={backgroundColor}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: 200,
              transformOrigin: "50% 0",
              transform: `scale(${scaleContents})`,
              margin: `30px -${(1 - scaleContents) * 100}% calc(-200px * ${
                1 - scaleContents
              })`,
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                textTransform: "uppercase",
              }}
            >
              {stateOptions.greetingText}
            </Typography>
            <Typography variant={"h6"}>
              {stateOptions.greetingSecondaryText.replaceAll(
                "{waitingTime}",
                waitingTime
              )}
              {/*{t(stateOptions.greetingSecondaryText, { waitingTime })}*/}
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            transform: `scale(${scaleContents * extraScaleFactor})`,
            transformOrigin: "50% 0",
            margin: `0 0 calc(-70px * ${1 - scaleContents * extraScaleFactor})`,
            height: 70,
          }}
        >
          {state !== "offline" ? (
            <MetricsList
              metricTypes={metricTypes}
              data={data}
              color={primaryColor}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

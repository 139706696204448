import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grid, LinearProgress, Stack, Tab, Typography } from "@mui/material";
import { NavButton } from "./NavButton";
import { List as ListIcon, Map } from "@mui/icons-material";
import { LocationsMap } from "./LocationsMap";
import { LocationsList } from "./LocationsList";
import { useStores } from "../Hook/useStores";
import { useTranslation } from "react-i18next";

enum CurrentTab {
  LIST = "list",
  MAP = "map",
}

interface LocationsContentProps {}

export const LocationsContent: React.FunctionComponent<LocationsContentProps> =
  () => {
    const { t } = useTranslation("locations");
    const [tabValue, setTabValue] = useState(CurrentTab.LIST);
    const { data: stores, loading } = useStores({});

    if (loading) {
      return <LinearProgress variant={"indeterminate"} />;
    }

    return (
      <TabContext value={tabValue}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={8} xl={9}>
            <Typography variant={"h4"}>
              {t("ALL_LOCATIONS")}
              <Typography component={"span"} variant={"subtitle2"}>
                {" "}
                ({stores?.stores.length})
              </Typography>
            </Typography>
            <Typography variant={"h5"}>{t("LIST_OF_ALL_LOCATIONS")}</Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <TabList
              onChange={(event, newValue) => setTabValue(newValue)}
              variant={"fullWidth"}
              sx={{ border: "1px solid #999", borderRadius: 1 }}
            >
              <Tab
                label={
                  <NavButton
                    icon={<ListIcon />}
                    label={t("LIST")}
                    direction={"row"}
                  />
                }
                value={CurrentTab.LIST}
              />
              <Tab
                label={
                  <NavButton
                    icon={<Map />}
                    label={t("MAP")}
                    direction={"row"}
                  />
                }
                value={CurrentTab.MAP}
              />
            </TabList>
          </Grid>
        </Grid>

        <Stack flexGrow={1} height={"1px"} overflow={"auto"}>
          <TabPanel
            value={CurrentTab.LIST}
            sx={{ height: "100%", width: "100%", p: 0 }}
          >
            <LocationsList stores={stores?.stores || []} />
          </TabPanel>

          <TabPanel
            value={CurrentTab.MAP}
            sx={{ height: "100%", width: "100%", p: 0 }}
          >
            <LocationsMap stores={stores?.stores || []} />
          </TabPanel>
        </Stack>
      </TabContext>
    );
  };

import React from "react";
import { useTranslation } from "react-i18next";
import { Kpi } from "../../../generated/graphql";

interface Props {
  kpi: Kpi;
}

export const KpiDescription: React.FunctionComponent<Props> = ({ kpi }) => {
  const { t } = useTranslation();

  return t(`${kpi}_description`, { ns: "kpi" });
};

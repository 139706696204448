import React from "react";
import { ChartData } from "chart.js/auto";
import { Box } from "@mui/material";
import { ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import { interpolateRainbow } from "d3-scale-chromatic";
type Props = { data: ChartData };

export const KpiLineChart: React.FunctionComponent<Props> = ({ data }) => {
  const chartContainsData = data.labels && data.labels.length > 0;
  const chartData: ChartData = {
    ...data,
    datasets: chartContainsData
      ? data.datasets.map((dataset, i) => {
          const color = interpolateRainbow((1 / data.datasets.length) * i);
          return {
            ...dataset,
            backgroundColor: color,
            borderColor: color,
            fill: false,
            borderWidth: 1,
          };
        })
      : [],
  };
  const options: ChartOptions<"line"> = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <Box sx={{ m: 3, pb: "6em", height: "1" }}>
      <Line data={chartData} options={options} />
    </Box>
  );
};

import React from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MetricType } from "../Dashboard/MetricItem";
import { EditorPanelContainer } from "./EditorPanelContainer";

interface Props {
  selectedMetricTypes: MetricType[];
  onMetricSelectionChanged: (metrics: MetricType[]) => void;
}

export const LayoutPreviewMetricSelection: React.FunctionComponent<Props> = ({
  selectedMetricTypes,
  onMetricSelectionChanged,
}) => {
  const { t } = useTranslation("fillLevel");
  const handleChange = (type: MetricType, checked: boolean) => {
    let newMetrics = [...selectedMetricTypes];

    if (checked && !selectedMetricTypes.includes(type)) {
      newMetrics.push(type);
    }

    if (!checked) {
      newMetrics = newMetrics.filter((item) => item !== type);
    }

    onMetricSelectionChanged(newMetrics);
  };

  const metricTypes: MetricType[] = [
    "current",
    "available",
    "limit",
    "occupancy",
  ];

  return (
    <EditorPanelContainer headline={t("choose_metrics")}>
      <Box component={"form"}>
        <FormGroup>
          {metricTypes.map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  checked={selectedMetricTypes.includes(type)}
                  onChange={(event) =>
                    handleChange(type, event.currentTarget.checked)
                  }
                />
              }
              label={t("metric_type_" + type)}
            />
          ))}
        </FormGroup>
      </Box>
    </EditorPanelContainer>
  );
};

import React from "react";
import { useLogin } from "./Core/Authentication/Hook/useLogin";
import { I18nextProvider } from "react-i18next";
import { useAuthenticationInformation } from "./Core/Authentication/Hook/useAuthenticationInformation";
import i18n from "i18next";
import { LoginScreen } from "../ui-component-library/Authentication/LoginScreen";
import { usePasswordReset } from "./Core/Authentication/Hook/usePasswordReset";

interface Props {}

export const UnauthenticatedApp: React.FunctionComponent<Props> = () => {
  const { doLogin, isLoading, isError } = useLogin();
  const { isFetching } = useAuthenticationInformation();
  const { doResetPassword } = usePasswordReset();

  return (
    <I18nextProvider i18n={i18n}>
      <LoginScreen
        onLogin={(username, password) => doLogin({ username, password })}
        onPasswordReset={(username) => doResetPassword(username)}
        isLoading={isLoading || isFetching}
        credentialsInvalid={isError}
      />
    </I18nextProvider>
  );
};

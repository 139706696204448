import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListProps,
  Stack,
  Typography,
} from "@mui/material";
import { Circle, Settings } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface LocationMenuNode {
  title: string;
  avatar?: JSX.Element;
  url?: string;
  settingsUrl?: string;
  monitoringState?: "success" | "warning" | "error";
  children: LocationMenuNode[];
}

interface LocationMenuListItemProps {
  node: LocationMenuNode;
}

const LocationMenuListItem: React.FunctionComponent<LocationMenuListItemProps> =
  ({ node }) => {
    const navigate = useNavigate();

    const handleSettingsClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (node.settingsUrl) {
        navigate(node.settingsUrl);
      }
    };

    return (
      <>
        <ListItem
          sx={{
            border: "1px solid #999",
            borderRadius: 1,
            mb: 1,
            p: 0.5,
            width: "auto",
          }}
          button={true}
          onClick={() => (node.url ? navigate(node.url) : undefined)}
          secondaryAction={
            <Stack direction={"row"}>
              {node.monitoringState && (
                <Box
                  sx={{ display: "inline-flex", alignItems: "center", p: 1 }}
                >
                  <Circle color={node.monitoringState} />
                </Box>
              )}
              {node.settingsUrl && (
                <IconButton onClick={handleSettingsClick}>
                  <Settings />
                </IconButton>
              )}
            </Stack>
          }
        >
          {node.avatar && (
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: "transparent" }}>
                {node.avatar}
              </Avatar>
            </ListItemAvatar>
          )}
          <ListItemText>
            <Typography noWrap={true} title={node.title}>
              {node.title}
            </Typography>
          </ListItemText>
        </ListItem>

        {node.children.length > 0 && (
          <LocationMenuList nodes={node.children} sx={{ ml: 3, py: 0 }} />
        )}
      </>
    );
  };

interface LocationMenuListProps extends ListProps {
  nodes: LocationMenuNode[];
}

export const LocationMenuList: React.FunctionComponent<LocationMenuListProps> =
  ({ nodes, ...listProps }) => {
    return (
      <List sx={{ py: 0 }} {...listProps}>
        {nodes.map((node, index) => (
          <LocationMenuListItem key={index} node={node} />
        ))}
      </List>
    );
  };

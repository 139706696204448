import React from "react";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem } from "@mui/material";

interface NavigationMenuItem {
  label: React.ReactNode;
  path: string;
}

interface Props {
  menuItems: NavigationMenuItem[];
}

export const NavigationMenuButton: React.FunctionComponent<Props> = ({
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((menuItem, index) => (
          <MenuItem
            onClick={handleClose}
            key={index}
            component={RouterLink}
            to={menuItem.path}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
